import { useTranslation } from "react-i18next";
import styles from "./DeleteCartModal.module.scss";
import { useState } from "react";
import { RiDeleteBinLine } from "@remixicon/react";
import { Alert, Button, Text, Modal, ModalButtons } from "@eventsquare/uikit";

import { Api } from "@lib/api";
import { removeCookie } from "@lib/cookies";

interface DeleteCartModalProps {
  cart: {
    cartid: string;
    saved_at: string | null;
  } | null;
  setCartNull: () => void;
  afterDelete: () => void;
}

export const DeleteCartModal = (props: DeleteCartModalProps) => {
  const { cart, setCartNull, afterDelete } = props;

  const { t } = useTranslation("components/delete_cart_modal");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const deleteCart = async () => {
    if (!cart) return;
    try {
      setError(null);
      setLoading(true);

      if (cart.cartid) {
        await Api.delete(`/cart/${cart.cartid}`);
      }

      await removeCookie();

      setCartNull();
      afterDelete();
    } catch (error) {
      //console.log(error);
      setError("something_went_wrong");
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    if (loading) return;
    setCartNull();
  };

  return (
    <Modal
      isVisible={!!cart}
      closeModal={closeModal}
      icon={RiDeleteBinLine}
      iconColor="error"
    >
      <div className={styles.deleteCartModal}>
        <div className={styles.deleteCartModal__info}>
          <Text variant="h3" color="error">
            {cart?.saved_at ? t("title_option") : t("title_cart")}
          </Text>
          <Text variant="p">
            {cart?.saved_at ? t("lead_option") : t("lead_cart")}
          </Text>
        </div>
        {error && <Alert type="error" message={t(`errors.${error}`)} />}
        <ModalButtons>
          <Button block color="error" onClick={deleteCart} loading={loading}>
            {cart?.saved_at ? t("delete_option") : t("delete_cart")}
          </Button>
          <Button block variant="link" color="subtle" onClick={closeModal}>
            {t("cancel")}
          </Button>
        </ModalButtons>
      </div>
    </Modal>
  );
};
