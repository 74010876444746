import { ChannelCartType } from "@type/channelCart";
import styles from "./DeleteTypeFromCartModal.module.scss";
import { Alert, Button, Modal, ModalButtons, Text } from "@eventsquare/uikit";
import { RiDeleteBinLine } from "@remixicon/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { ChannelsContext } from "@components/ChannelsWrapper";
import { Api } from "@lib/api";

interface DeleteTypeFromCartModalProps {
  deleteType: ChannelCartType | null;
  closeModal: () => void;
}

export const DeleteTypeFromCartModal = (
  props: DeleteTypeFromCartModalProps
) => {
  const { deleteType, closeModal } = props;

  const { cart, setCart } = useOutletContext<ChannelsContext>();

  const { t } = useTranslation("components/delete_type_from_cart_modal");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const deleteFromCart = async (type: ChannelCartType) => {
    if (!cart || !type) return;

    setLoading(true);
    setError(null);

    try {
      const data = {
        show: type.show?.id,
        seatmap: type.seatmap?.id,
        price: type.price,
      };
      const response = await Api.delete(
        `/cart/${cart.cartid}/types/${type.id}`,
        data
      );

      setCart(response.cart);
      closeModal();
    } catch (error) {
      setError("something_went_wrong");
    } finally {
      setLoading(false);
    }
  };

  const close = () => {
    if (loading) return;
    closeModal();
  };

  return (
    <Modal
      isVisible={!!deleteType}
      closeModal={close}
      icon={RiDeleteBinLine}
      iconColor="error"
    >
      {deleteType && (
        <div className={styles.deleteTypeFromCartModal}>
          <div className={styles.deleteTypeFromCartModal__info}>
            <Text variant="h3" color="error">
              {deleteType.name}
            </Text>
            <Text variant="p">
              {deleteType.quantity === 1
                ? t("lead_ticket")
                : t("lead_tickets", { quantity: deleteType.quantity })}
            </Text>
            {error && <Alert type="error" message={t(`errors.${error}`)} />}
          </div>
          <ModalButtons>
            <Button
              block
              color="error"
              onClick={() => deleteFromCart(deleteType)}
              loading={loading}
            >
              {t("delete")}
            </Button>
            <Button block variant="link" color="subtle" onClick={close}>
              {t("cancel")}
            </Button>
          </ModalButtons>
        </div>
      )}
    </Modal>
  );
};
