export const currencySymbols = {
  //   LEK: "Lek", // Albanian Lek
  //   AFN: "؋",    // Afghan Afghani
  //   ARS: "$",   // Argentine Peso
  //   AWG: "ƒ",   // Aruban Florin
  //   AUD: "$",   // Australian Dollar
  //   AZN: "₼",   // Azerbaijani Manat
  //   BSD: "$",   // Bahamian Dollar
  //   BBD: "$",   // Barbadian Dollar
  //   BYN: "Br",  // Belarusian Ruble
  //   BZD: "BZ$", // Belize Dollar
  //   BMD: "$",   // Bermudian Dollar
  //   BOB: "Bs.", // Bolivian Boliviano
  //   BAM: "KM",  // Bosnia-Herzegovina Convertible Mark
  //   BWP: "P",   // Botswanan Pula
  //   BGN: "лв",  // Bulgarian Lev
  //   BRL: "R$",  // Brazilian Real
  //   BND: "$",   // Brunei Dollar
  //   KHR: "៛",   // Cambodian Riel
  //   CAD: "$",   // Canadian Dollar
  //   KYD: "$",   // Cayman Islands Dollar
  //   CLP: "$",   // Chilean Peso
  //   CNY: "¥",   // Chinese Yuan
  //   COP: "$",   // Colombian Peso
  //   CRC: "₡",   // Costa Rican Colón
  //   HRK: "kn",  // Croatian Kuna
  //   CUP: "₱",   // Cuban Peso
  //   CZK: "Kč",  // Czech Republic Koruna
  DKK: "kr", // Danish Krone
  //   DOP: "RD$", // Dominican Peso
  //   XCD: "$",   // East Caribbean Dollar
  //   EGP: "£",   // Egyptian Pound
  //   SVC: "$",   // Salvadoran Colón
  EUR: "€", // Euro
  //   FKP: "£",   // Falkland Islands Pound
  //   FJD: "$",   // Fijian Dollar
  //   GHS: "¢",   // Ghanaian Cedi
  //   GIP: "£",   // Gibraltar Pound
  //   GTQ: "Q",   // Guatemalan Quetzal
  //   GGP: "£",   // Guernsey Pound
  //   GYD: "$",   // Guyanaese Dollar
  //   HNL: "L",   // Honduran Lempira
  //   HKD: "$",   // Hong Kong Dollar
  //   HUF: "Ft",  // Hungarian Forint
  //   ISK: "kr",  // Icelandic Króna
  //   INR: "₹",   // Indian Rupee
  //   IDR: "Rp",  // Indonesian Rupiah
  //   IRR: "﷼",    //Iranian Rial
  //   IMP: "£",   // Isle of Man Pound
  //   ILS: "₪",   // Israeli New Sheqel
  //   JMD: "J$",  // Jamaican Dollar
  //   JPY: "¥",   // Japanese Yen
  //   JEP: "£",   // Jersey Pound
  //   KZT: "лв",  // Kazakhstani Tenge
  //   KPW: "₩",   // North Korean Won
  //   KRW: "₩",   // South Korean Won
  //   KGS: "лв",  // Kyrgystani Som
  //   LAK: "₭",   // Laotian Kip
  //   LBP: "£",   // Lebanese Pound
  //   LRD: "$",   // Liberian Dollar
  //   MKD: "ден", // Macedonian Denar
  //   MYR: "RM",  // Malaysian Ringgit
  //   MUR: "₨",   // Mauritian Rupee
  //   MXN: "$",   // Mexican Peso
  //   MNT: "₮",   // Mongolian Tugrik
  //   MZN: "MT",  // Mozambican Metical
  //   NAD: "$",   // Namibian Dollar
  //   NPR: "₨",   // Nepalese Rupee
  //   ANG: "ƒ",   // Netherlands Antillean Guilder
  //   NZD: "$",   // New Zealand Dollar
  //   NIO: "C$",  // Nicaraguan Córdoba
  //   NGN: "₦",   // Nigerian Naira
  NOK: "kr", // Norwegian Krone
  //   OMR: "﷼",    // Omani Rial
  //   PKR: "₨",   // Pakistani Rupee
  //   PAB: "B/.", // Panamanian Balboa
  //   PYG: "₲",   // Paraguayan Guarani
  //   PEN: "S/.", // Peruvian Nuevo Sol
  //   PHP: "₱",   // Philippine Peso
  PLN: "zł", // Polish Zloty
  //   QAR: "﷼",    //Qatari Rial
  //   RON: "lei", // Romanian Leu
  //   RUB: "₽",   // Russian Ruble
  //   SHP: "£",   // Saint Helena Pound
  //   SAR: "﷼",    //Saudi Riyal
  //   RSD: "Дин.",// Serbian Dinar
  //   SCR: "₨",   // Seychellois Rupee
  //   SGD: "$",   // Singapore Dollar
  //   SBD: "$",   // Solomon Islands Dollar
  //   SOS: "S",   // Somali Shilling
  //   ZAR: "R",   // South African Rand
  //   LKR: "₨",   // Sri Lankan Rupee
  SEK: "kr", // Swedish Krona
  CHF: "CHF", // Swiss Franc
  //   SRD: "$",   // Surinamese Dollar
  //   SYP: "£",   // Syrian Pound
  //   TWD: "NT$", // New Taiwan Dollar
  //   THB: "฿",   // Thai Baht
  //   TTD: "TT$", // Trinidad and Tobago Dollar
  //   TRY: "₺",   // Turkish Lira
  //   TVD: "$",   // Tuvaluan Dollar
  //   UAH: "₴",   // Ukrainian Hryvnia
  GBP: "£", // British Pound Sterling
  USD: "$", // US Dollar
  //   UYU: "$U",  // Uruguayan Peso
  //   UZS: "лв",  // Uzbekistan Som
  //   VEF: "Bs",  // Venezuelan Bolívar
  //   VND: "₫",   // Vietnamese Dong
  //   YER: "﷼",    //Yemeni Rial
  //   ZWD: "Z$",  // Zimbabwean Dollar
};
