import React from "react";
import styles from "./ChannelLayout.module.scss";
import { Button, Text } from "@eventsquare/uikit";
import { RiCloseLine } from "@remixicon/react";
import { useNavigate } from "react-router-dom";

import { getCookie, removeCookie } from "@lib/cookies";
import { useTranslation } from "react-i18next";

interface ChannelLayoutProps {
  children: React.ReactNode;
  event: string;
  channel: string | null;
  setDeleteCart: (cart: { cartid: string; saved_at: string | null }) => void;
}

export const ChannelLayout = (props: ChannelLayoutProps) => {
  const { children, event, channel, setDeleteCart } = props;

  const navigate = useNavigate();
  const { t } = useTranslation("channel_layout");

  const closeCart = () => {
    const cookie = getCookie();
    if (!cookie || !cookie.cart_id) return;

    if (cookie.saved_at) {
      // if this cart is already an option, just remove cookie and navigate
      removeCookie();
      navigate("/");
    } else {
      // else pass cart to delete modal
      setDeleteCart({
        cartid: cookie.cart_id,
        saved_at: cookie.saved_at ?? null,
      });
    }
  };

  return (
    <div className={styles.channelLayout}>
      <div className={styles.channelLayout__topbar}>
        <div>
          <Text variant="h5" noMargin>
            {event}
          </Text>
          {channel && (
            <Text variant="h6" noMargin>
              {channel}
            </Text>
          )}
        </div>
        <div>
          <Button
            variant="outline"
            size="tiny"
            onClick={closeCart}
            color="subtle"
          >
            <RiCloseLine />
            {t("close")}
          </Button>
        </div>
      </div>
      {children}
    </div>
  );
};
