import { Channel } from "@type/channel";
import { ChannelCart } from "@type/channelCart";
import { ChannelType } from "@type/channelEdition";
import { User } from "@type/user";
import { Currency } from "@type/currency";

import { currencySymbols } from "@lib/currencySymbols";

export const formatCurrency = (amount: number, currency?: Currency) => {
  // get locale from html lang attribute
  const locale = document.documentElement.lang ?? "nl";
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency ?? "EUR",
  }).format(amount);
};

export const formatHexToRgb = (hex: string): string => {
  let r, g, b;

  const hexCode = hex.replace("#", "");

  if (hexCode.length === 3) {
    r = parseInt(hexCode.slice(0, 1).repeat(2), 16);
    g = parseInt(hexCode.slice(1, 2).repeat(2), 16);
    b = parseInt(hexCode.slice(2, 3).repeat(2), 16);
  } else if (hexCode.length === 6) {
    r = parseInt(hexCode.slice(0, 2), 16);
    g = parseInt(hexCode.slice(2, 4), 16);
    b = parseInt(hexCode.slice(4, 6), 16);
  } else {
    // Default to white
    r = 255;
    g = 255;
    b = 255;
  }

  return `${r}, ${g}, ${b}`;
};

export const getQuantity = (
  type: ChannelType | undefined,
  cart: ChannelCart | undefined,
  showId: string | undefined = undefined
) => {
  if (!type || !cart) return 0;
  const typeId = type.id;
  let filteredCardTypes = cart.items.types.filter(
    (cartType) => cartType.id === typeId && !cartType.voucher
  );
  if (showId) {
    filteredCardTypes = filteredCardTypes.filter(
      (cartType) => cartType?.show?.id && cartType.show.id === showId
    );
  }
  if (filteredCardTypes.length === 0) return 0;
  return filteredCardTypes[0].quantity;
};

export const formatTimer = (numberOfSeconds: number): string => {
  const days = Math.floor(numberOfSeconds / 86400);
  const hours = Math.floor((numberOfSeconds - days * 86400) / 3600);
  const min = Math.floor((numberOfSeconds - days * 86400 - hours * 3600) / 60);
  const sec = numberOfSeconds - days * 86400 - hours * 3600 - min * 60;

  const hoursVis = hours.toString().padStart(2, "0");
  const minVis = min.toString().padStart(2, "0");
  const secVis = sec.toString().padStart(2, "0");

  let response: string = minVis + ":" + secVis;
  if (hours) response = hoursVis + ":" + response;
  if (days) response = days.toString() + ":" + response;

  return response;
};

export const formatDate = (sqldate: string, locale: string): string => {
  const date = sqldate.replace(/-/g, "/");
  const d = new Date(date);
  return d.toLocaleDateString(locale, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const formatDateTime = (sqldate: string, locale: string): string => {
  const date = sqldate.replace(/-/g, "/");
  const d = new Date(date);
  return d.toLocaleDateString(locale, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formatShowDate = (
  sqldate: string,
  hide_hours: boolean,
  locale: string
): string => {
  const date = sqldate.replace(/-/g, "/");
  const d = new Date(date);
  const options: {
    weekday: "long";
    year: "numeric";
    month: "long";
    day: "numeric";
    hour12: false;
    hour?: "2-digit";
    minute?: "2-digit";
  } = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour12: false,
  };

  if (!hide_hours) {
    options.hour = "2-digit";
    options.minute = "2-digit";
  }

  const dateString = d.toLocaleDateString(locale, options);
  // return first letter uppercase
  return dateString.charAt(0).toUpperCase() + dateString.slice(1);
};

export const getBadgeColorFromStatus = (status: string) => {
  switch (status) {
    case "available":
      return "info";
    case "low":
      return "warning";
    case "soldout":
      return "error";
    default:
      return "disabled";
  }
};

export const getUserName = (user: User | null) => {
  if (!user) return "-";
  if (user.firstname && user.lastname) {
    return `${user.firstname} ${user.lastname}`;
  }
  if (user.email) {
    return user.email;
  }
  return "-";
};

export const sortChannels = (
  channelsArr: Channel[] | null,
  sortingMethod: string
) => {
  if (!channelsArr) return [];

  const now = Date.now();

  switch (sortingMethod) {
    case "event_name": {
      return [...channelsArr].sort((a, b) => {
        return a.event.name.trim() < b.event.name.trim() ? -1 : 1;
      });
    }
    case "edition_creation_date": {
      return [...channelsArr].sort((a, b) =>
        a.edition.created_at > b.edition.created_at ? -1 : 1
      );
    }
    case "edition_date": {
      const noDates = [...channelsArr]
        .filter((channel) => !channel.edition.dates.start)
        .sort((a, b) => (a.edition.created_at > b.edition.created_at ? -1 : 1));
      const hasDates = [...channelsArr].filter(
        (channel) => !!channel.edition.dates.start
      );
      const futureDates = hasDates
        .filter(
          (channel) =>
            !!channel.edition.dates.start &&
            Date.parse(channel.edition.dates.start) > now
        )
        .sort((a, b) =>
          (a.edition.dates.start as string) < (b.edition.dates.start as string)
            ? -1
            : 1
        );
      const pastDates = hasDates
        .filter(
          (channel) =>
            !!channel.edition.dates.start &&
            Date.parse(channel.edition.dates.start) <= now
        )
        .sort((a, b) =>
          (a.edition.dates.start as string) > (b.edition.dates.start as string)
            ? -1
            : 1
        );

      return [...futureDates, ...pastDates, ...noDates];
    }

    default:
      return [...channelsArr];
  }
};

export const getCurrencySymbol = (currency: Currency): string => {
  // check if currency is in the list
  if (currency in currencySymbols) {
    return currencySymbols[currency as keyof typeof currencySymbols] as string;
  }
  // default to currency code
  return currency;
};

export const hasSeatmapTypes = (cart: ChannelCart): boolean => {
  return cart.items.types.some((type) => type.seatmap);
};
