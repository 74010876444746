import { Button, Modal, Text, useAuth } from "@eventsquare/uikit";
import styles from "./EditPriceModal.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getCurrencySymbol } from "@lib/helpers";
import { Currency } from "@type/currency";

interface EditPriceModalProps {
  activePrice: number | null;
  setActivePrice: (price: number | null) => void;
  showModal: boolean;
  closeModal: () => void;
}

export const EditPriceModal = (props: EditPriceModalProps) => {
  const { activePrice, setActivePrice, showModal, closeModal } = props;

  const { account } = useAuth();

  const { t } = useTranslation("components/edit_price_modal");

  const [newPrice, setNewPrice] = useState<number | undefined>(undefined);
  //   const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setNewPrice(showModal && activePrice !== null ? activePrice : undefined);
  }, [showModal]);

  const editPrice = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPrice === activePrice) return;
    setActivePrice(newPrice === undefined ? null : +newPrice);
    closeModal();
  };

  return (
    <Modal isVisible={showModal} closeModal={closeModal}>
      <div className={styles.editPriceModal}>
        <form onSubmit={editPrice}>
          <Text variant="h3" color="info">
            {t("title")}
          </Text>
          <div className={styles.editPriceModal__input}>
            <input
              name="price"
              type={"number" as unknown as "text"}
              value={newPrice?.toString()}
              onChange={(e) => setNewPrice(+e.target.value)}
              min={0}
              step={0.01}
            />
            <span className={styles.editPriceModal__input_currency}>
              {getCurrencySymbol(account?.currency as Currency)}
            </span>
          </div>
          <Button block>{t("submit")}</Button>
        </form>
      </div>
    </Modal>
  );
};
