import { useEffect, useState } from "react";
import styles from "./Show.module.scss";
import { Button, Text } from "@eventsquare/uikit";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiArrowLeftLine } from "@remixicon/react";

import { Api } from "@lib/api";

import { ChannelsContext } from "@components/ChannelsWrapper";
import { Tickets } from "@components/Tickets/Tickets";
import { PageLoader } from "@components/PageLoader/PageLoader";
import { Seatmap } from "@components/Seatmap/Seatmap";

import { ChannelSeatmap, ChannelShow, ChannelType } from "@type/channelEdition";
import { formatShowDate } from "@lib/helpers";

interface ShowProps {
  storeUrl: string;
  posToken: string;
  activeShowId: string;
  setActiveShowId: (id: string | null) => void;
}

export const Show = (props: ShowProps) => {
  const { storeUrl, posToken, activeShowId, setActiveShowId } = props;

  const { cart, seatmapTypeInCartToEdit } = useOutletContext<ChannelsContext>();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState<ChannelShow | null>(null);
  const [seatmap, setSeatmap] = useState<ChannelSeatmap | undefined>(undefined);
  const [useSeatmapEditor, setUseSeatmapEditor] = useState(false);

  const { t, i18n } = useTranslation("components/show");

  const fetchSeatmap = async (seatmapId: string) => {
    if (!cart) return;
    try {
      const params = {
        cart: cart.cartid,
        //language: i18n.language,
      };
      const response = await Api.get(
        `${storeUrl}/${activeShowId}/${seatmapId}`,
        params
      );
      setSeatmap(response.seatmap);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchShow = async (activeShowId: string) => {
    if (!activeShowId || !cart?.cartid) return;

    try {
      setLoading(true);
      const response = await Api.get(`${storeUrl}/${activeShowId}`, {
        pos_token: posToken,
        cart: cart.cartid,
      });
      setShow(response.show);
      if (response.show.seatmaps.length > 0) {
        fetchSeatmap(response.show.seatmaps[0].id);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!activeShowId) {
      setShow(null);
      return;
    }

    fetchShow(activeShowId);
  }, [activeShowId]);

  useEffect(() => {
    if (seatmapTypeInCartToEdit) {
      setUseSeatmapEditor(true);
    }
  }, [seatmapTypeInCartToEdit]);

  const getShowName = (show: ChannelShow) => {
    if (show.name) return show.name;
    if (show?.date?.start)
      return formatShowDate(
        show.date.start,
        show.date.hide_hours,
        i18n.language
      );
    return show.id;
  };

  if (!activeShowId) return null;

  if (loading || !show) {
    return <PageLoader />;
  }

  return (
    <div className={styles.show}>
      <div className={styles.show__header}>
        <div className={styles.show__close}>
          <button
            className={styles.show__closeButton}
            onClick={() => setActiveShowId(null)}
          >
            <RiArrowLeftLine />
            <span>{t("go_back_to_shows")}</span>
          </button>
        </div>
        <div className={styles.show__headerTitle}>
          <div>
            <Text variant="h3" noMargin>
              {getShowName(show)}
            </Text>
            {show?.name && show?.date?.start && (
              <Text variant="h6">
                {formatShowDate(
                  show.date.start,
                  show.date.hide_hours,
                  i18n.language
                )}
              </Text>
            )}
          </div>
          {!!seatmap && (
            <Button
              variant="outline"
              onClick={() => setUseSeatmapEditor(!useSeatmapEditor)}
              size="small"
            >
              {useSeatmapEditor ? t("hide_seatmap") : t("show_seatmap")}
            </Button>
          )}
        </div>
      </div>

      {useSeatmapEditor ? (
        <Seatmap seatmapData={seatmap} show={show} />
      ) : (
        <>
          {!!seatmap?.types?.length && (
            <Tickets
              types={seatmap.types as unknown as ChannelType[]}
              showId={activeShowId}
              seatmapId={seatmap.id}
            />
          )}

          {!!show?.types?.length && (
            <Tickets
              types={show.types as unknown as ChannelType[]}
              showId={activeShowId}
              // title={activeShow.name ?? }
            />
          )}
        </>
      )}
    </div>
  );
};
