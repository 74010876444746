import { Outlet } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "@eventsquare/uikit";

import { Api } from "@lib/api";
import { getCookie, setCookie } from "@lib/cookies";

import { Channel } from "@type/channel";
import { ChannelCart, ChannelCartType } from "@type/channelCart";

export interface ChannelsContext {
  channels: Channel[];
  channelsLoading: boolean;
  activeChannel: Channel | null;
  setActiveChannel: (channel: Channel | null) => void;
  cart: ChannelCart | null;
  cartLoading: boolean;
  setCartLoading: (loading: boolean) => void;
  cartIsBeingModified: boolean;
  setCartIsBeingModified: (modifing: boolean) => void;
  setCart: (cart: ChannelCart | null) => void;

  seatmapTypeInCartToEdit: ChannelCartType | null;
  setSeatmapTypeInCartToEdit: (type: ChannelCartType | null) => void;

  customerLanguages: { code: string }[];
}

export const ChannelsWrapper = () => {
  const { accountId } = useAuth();

  const [channels, setChannels] = useState<Channel[] | null>(null);
  const [channelsLoading, setChannelsLoading] = useState<boolean>(true);

  const [cart, setCart] = useState<ChannelCart | null>(null);
  const [cartLoading, setCartLoading] = useState<boolean>(true);
  const [cartIsBeingModified, setCartIsBeingModified] =
    useState<boolean>(false);

  const [seatmapTypeInCartToEdit, setSeatmapTypeInCartToEdit] =
    useState<ChannelCartType | null>(null);

  const [customerLanguages, setCustomerLanguages] = useState<
    { code: string }[]
  >([]);

  const fetchChannels = useCallback(async () => {
    setChannelsLoading(true);
    try {
      const data = await Api.get(`/accounts/${accountId}/pos`);
      setChannels(data.channels);
    } catch (error) {
      if (Api.isApiError(error)) {
        //console.log(error.response);
      }
    } finally {
      setChannelsLoading(false);
    }
  }, [accountId]);

  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  const handleSetCart = (cart: ChannelCart | null) => {
    if (!cart) {
      setCart(null);
      return;
    }

    // calculate the expiration time of the cart get expires_in (in seconds) and add it to now
    const expires_date = new Date();
    expires_date.setSeconds(expires_date.getSeconds() + cart.expires_in);

    const cookie = getCookie() ?? {};
    setCookie(
      {
        ...cookie,
        cart_id: cart.cartid,
        saved_at: cart.saved_at,
      },
      { maxAge: cart.expires_in }
    );

    setCart({ ...cart, expires_date: expires_date });
  };

  const fetchCustomersLanguages = useCallback(async () => {
    try {
      const data = await Api.get(`/lists/languages`, {
        type: "customers",
      });
      setCustomerLanguages(data.languages);
    } catch (error) {
      if (Api.isApiError(error)) {
        //console.log(error.response);
      }
    }
  }, []);

  useEffect(() => {
    fetchCustomersLanguages();
  }, [fetchCustomersLanguages]);

  return (
    <Outlet
      context={{
        channels,
        channelsLoading,
        cart,
        cartLoading,
        setCartLoading,
        cartIsBeingModified,
        setCartIsBeingModified,
        setCart: handleSetCart,
        seatmapTypeInCartToEdit,
        setSeatmapTypeInCartToEdit,
        customerLanguages,
      }}
    />
  );
};
