import styles from "./SaveCartModal.module.scss";
import { useEffect, useState } from "react";
import { RiLockStarLine } from "@remixicon/react";
import {
  Alert,
  Button,
  Text,
  Form,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  FormTextArea,
  Modal,
  ModalButtons,
} from "@eventsquare/uikit";

import { ChannelCart } from "@type/channelCart";
import { Api } from "@lib/api";
import { useTranslation } from "react-i18next";
import { removeCookie } from "@lib/cookies";

interface SaveCartModalProps {
  cart: ChannelCart | null;
  setCartNull: () => void;
  afterSave: () => void;
}

interface SaveFormValues {
  reference: string;
  expires_in: string;
  note: string;
}

interface Error {
  response: {
    body: {
      message: string;
      error: string;
      validation: {
        reference?: string[];
        minutes?: string[];
      };
    };
  };
}

export const SaveCartModal = (props: SaveCartModalProps) => {
  const { cart, setCartNull, afterSave } = props;

  const [formValues, setFormValues] = useState<SaveFormValues | undefined>(
    undefined
  );

  const [errors, setErrors] = useState<string[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { t } = useTranslation("components/save_cart_modal");

  const expiresInOptions = [
    { value: "4320", label: t("expire_options.3_days") },
    { value: "10080", label: t("expire_options.1_week") },
    { value: "43800", label: t("expire_options.1_month") },
    { value: "262800", label: t("expire_options.6_months") },
    { value: "525600", label: t("expire_options.1_year") },
    { value: "1576800", label: t("expire_options.3_years") },
  ];

  useEffect(() => {
    if (!cart) {
      setFormValues(undefined);
      return;
    }

    setFormValues({
      reference: cart.reference ?? "",
      expires_in: "262800",
      note: "",
    });
  }, [cart]);

  const saveCart = async (
    e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();
    if (!cart || !formValues) return;
    try {
      setErrors(null);
      setLoading(true);

      const data: {
        reference: string;
        minutes: string;
        note?: string;
      } = {
        reference: formValues.reference,
        minutes: formValues.expires_in,
      };

      if (formValues.note && formValues.note.trim().length > 0) {
        data.note = formValues.note;
      }
      const response = await Api.post(`/cart/${cart.cartid}/save`, data);
      await removeCookie();

      if (response.cart) {
        setSuccess(true);
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (Api.isApiError(error)) {
        console.error(error);
      }

      const err = (error as Error).response.body;
      if (err.error === "validation_failed") {
        const validationErrors: string[] = [];
        for (const [key, value] of Object.entries(err.validation)) {
          value.forEach((v: string) => {
            validationErrors.push(`${key}_${v}`);
          });
        }
        setErrors(validationErrors);
      } else {
        setErrors([err.error ?? "something_went_wrong"]);
      }
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    if (loading) return;
    setErrors(null);
    setCartNull();
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    if (loading) return;
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value } as SaveFormValues);
  };

  if (!formValues) return null;

  return (
    <Modal
      isVisible={!!cart}
      closeModal={closeModal}
      icon={RiLockStarLine}
      iconColor="info"
    >
      {!success && (
        <div className={styles.saveCartModal}>
          <div className={styles.saveCartModal__info}>
            <Text variant="h3" color="info">
              {cart?.saved_at ? t("title_settings") : t("title_save")}
            </Text>
            {!cart?.saved_at && <Text variant="p">{t("lead")}</Text>}
          </div>

          {errors && errors.length && (
            <Alert
              type="error"
              message={errors.map((error) => t(`errors.${error}`))}
            />
          )}
          <div className={styles.saveCartModal__data}>
            <Form onSubmit={saveCart} loading={false}>
              <FormGroup>
                <FormLabel htmlFor="reference">{t("form.reference")}</FormLabel>
                <FormInput
                  type="text"
                  name="reference"
                  // id="reference"
                  // autoFocus
                  value={formValues.reference}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel htmlFor="expires_in">
                  {t("form.expires_in")}
                </FormLabel>
                <FormSelect
                  name="expires_in"
                  // id="expires_in"
                  placeholder={t("form.expires_in_placeholder")}
                  onChange={handleChange}
                  value={formValues.expires_in}
                  options={expiresInOptions}
                />
              </FormGroup>
              {!cart?.saved_at && (
                <FormGroup>
                  <FormLabel htmlFor="note">{t("form.note")}</FormLabel>
                  <FormTextArea
                    name="note"
                    // id="note"
                    value={formValues.note}
                    onChange={handleChange}
                  />
                </FormGroup>
              )}
              <ModalButtons>
                <Button block onClick={saveCart} loading={loading}>
                  {t("form.submit")}
                </Button>
                <Button
                  block
                  variant="link"
                  color="subtle"
                  onClick={closeModal}
                >
                  {t("form.cancel")}
                </Button>
              </ModalButtons>
            </Form>
          </div>
        </div>
      )}

      {success && (
        <>
          <div className={styles.saveCartModal__info}>
            <Text variant="h3">{t("success.title")}</Text>
            <Text variant="p">{t("success.lead")}</Text>
          </div>
          <ModalButtons>
            <Button
              block
              onClick={() => {
                afterSave();
                setCartNull();
              }}
            >
              {t("success.ok")}
            </Button>
          </ModalButtons>
        </>
      )}
    </Modal>
  );
};
