import { useEffect, useState } from "react";
import styles from "./EditTypeModal.module.scss";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Alert, Button, Text, Modal, useAuth } from "@eventsquare/uikit";
import { RiSubtractLine, RiAddLine } from "@remixicon/react";

import { ChannelsContext } from "@components/ChannelsWrapper";

import { formatCurrency, getCurrencySymbol } from "@lib/helpers";
import { Api } from "@lib/api";

import { ActiveChannelType } from "@type/channelEdition";
import { ActiveChannelCartType } from "@type/channelCart";
import { Currency } from "@type/currency";

interface EditTypeModalProps {
  activeType: ActiveChannelType | ActiveChannelCartType | null;
  closeModal: () => void;
}

export const EditTypeModal = (props: EditTypeModalProps) => {
  const { activeType, closeModal } = props;

  const { cart, setCart } = useOutletContext<ChannelsContext>();

  const { account } = useAuth();

  const [originalQuantity, setOriginalQuantity] = useState<number | undefined>(
    undefined
  );
  const [newQuantity, setNewQuantity] = useState<number | undefined>(undefined);
  const [price, setPrice] = useState<number | undefined>(undefined);

  const [editPrice, setEditPrice] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation("components/edit_type_modal");

  useEffect(() => {
    if (activeType) {
      setOriginalQuantity(activeType.quantity);
      setNewQuantity(activeType.quantity === 0 ? 1 : activeType.quantity);
      setPrice(
        typeof activeType.fixedPrice === "number"
          ? activeType.fixedPrice
          : activeType.price
      );
    } else {
      setOriginalQuantity(undefined);
      setNewQuantity(undefined);
      setPrice(undefined);
      setEditPrice(false);
    }
  }, [activeType]);

  const close = () => {
    setError(null);
    setEditPrice(false);
    closeModal();
  };

  const editCart = async () => {
    if (!cart) return;

    setError(null);

    if (
      !activeType ||
      newQuantity === undefined ||
      originalQuantity === undefined
    ) {
      close();
      return;
    }

    if (newQuantity === originalQuantity) {
      close();
      return;
    }

    setLoading(true);
    setError(null);
    const changedQuantity = newQuantity - originalQuantity;

    const p =
      typeof price !== "undefined"
        ? price
        : activeType.fixedPrice || activeType.price;

    try {
      const params: {
        quantity: number;
        price: string;
        show?: string | null;
        seatmap?: string | null;
      } = {
        quantity: changedQuantity,
        price: p.toString(),
      };

      if (activeType.showId) {
        params.show = activeType.showId;
      }

      if (activeType.seatmapId) {
        params.seatmap = activeType.seatmapId;
      }

      const response = await Api.put(
        `/cart/${cart.cartid}/types/${activeType.id}`,
        params
      );

      setCart(response.cart);
      close();
    } catch (error) {
      if (Api.isApiError(error)) {
        setError(
          (error as { response: { body: { error: string } } }).response.body
            .error
        );
      } else {
        setError("something_went_wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!activeType) return;
    editCart();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    setNewQuantity(value);
  };

  const plusOne = () => {
    if (newQuantity === undefined) return;
    setNewQuantity(newQuantity + 1);
  };

  const minusOne = () => {
    if (newQuantity === undefined) return;
    if (newQuantity === 0) return;
    setNewQuantity(newQuantity - 1);
  };

  const getButtonText = (newQuantity: number, originalQuantity: number) => {
    if (newQuantity == originalQuantity) return t("button.close");
    if (originalQuantity == 0 && newQuantity > 0) return t("button.add");
    return t("button.modify");
  };

  if (
    !activeType ||
    newQuantity === undefined ||
    originalQuantity === undefined ||
    price === undefined
  )
    return null;

  return (
    <Modal isVisible={!!activeType} closeModal={close}>
      {activeType && (
        <div className={styles.editType}>
          <form onSubmit={handleSubmit}>
            <div className={styles.editType__info}>
              <Text variant="h3" color="info" noMargin>
                {activeType.name}
              </Text>
              {activeType.description && (
                <div className={styles.editType__description}>
                  <Text variant="p" noMargin>
                    {activeType.description}
                  </Text>
                </div>
              )}
              {!editPrice && (
                <>
                  <Text variant="p" noMargin>
                    {price
                      ? formatCurrency(price, account?.currency as Currency)
                      : t("free")}
                  </Text>
                  {activeType.fixedPrice === undefined && (
                    <button
                      className={styles.editType__modifyPriceButton}
                      onClick={() => setEditPrice(true)}
                    >
                      {t("modify_price")}
                    </button>
                  )}
                </>
              )}
              {activeType.fixedPrice === undefined && editPrice && (
                <div className={styles.editType__modifyPriceBox}>
                  <input
                    name="price"
                    type={"number" as unknown as "text"}
                    value={price?.toString()}
                    onChange={(e) => setPrice(+e.target.value)}
                    min={0}
                    step={0.01}
                  />
                  <span className={styles.editType__modifyPriceBox_currency}>
                    {getCurrencySymbol(account?.currency as Currency)}
                  </span>
                </div>
              )}
            </div>
            {error && (
              <Alert
                type="error"
                message={t(`errors.${error}`, {
                  name: activeType.name,
                  max: activeType.max_pp,
                })}
              />
            )}
            <div className={styles.editType__buttonGroup}>
              <button
                className={styles.editType__button}
                onClick={minusOne}
                disabled={!newQuantity || newQuantity <= 0 || loading}
                type="button"
              >
                <RiSubtractLine />
              </button>
              <input
                className={styles.editType__input}
                value={newQuantity}
                type="number"
                onChange={handleInputChange}
                step={1}
                min={0}
                disabled={loading}
              />
              <button
                className={styles.editType__button}
                onClick={plusOne}
                type="button"
                disabled={loading}
              >
                <RiAddLine />
              </button>
            </div>
            <div>
              <Button block loading={loading} color="primary">
                {getButtonText(newQuantity, originalQuantity)}
              </Button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};
