import styles from "./CartExpirationModal.module.scss";
import { RiEmotionSadLine } from "@remixicon/react";
import { Button, Text, Modal, ModalButtons } from "@eventsquare/uikit";
import { useTranslation } from "react-i18next";

interface CartExpirationModalProps {
  showExpiredCartModal: boolean;
  setShowExpiredCartModal: (isExpired: boolean) => void;
}

export const CartExpirationModal = (props: CartExpirationModalProps) => {
  const { showExpiredCartModal, setShowExpiredCartModal } = props;

  const { t } = useTranslation("components/cart_expiration_modal");

  const restart = () => {
    setShowExpiredCartModal(false);
    window.location.reload();
  };

  return (
    <Modal
      isVisible={showExpiredCartModal}
      closeModal={restart}
      icon={RiEmotionSadLine}
      iconColor="info"
    >
      <div className={styles.cartExpirationModal}>
        <div className={styles.cartExpirationModal__info}>
          <Text variant="h3" color="info">
            {t("title")}
          </Text>
          <Text variant="p">{t("message")}</Text>
        </div>
        <ModalButtons>
          <Button block onClick={restart}>
            {t("restart")}
          </Button>
        </ModalButtons>
      </div>
    </Modal>
  );
};
