import { ChannelsContext } from "@components/ChannelsWrapper";
import { useOutletContext } from "react-router-dom";
import styles from "./Notes.module.scss";
import {
  Form,
  Text,
  FormTextArea,
  FormGroup,
  Button,
  Alert,
  useAuth,
} from "@eventsquare/uikit";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Api } from "@lib/api";
import { formatDateTime } from "@lib/helpers";

export const Notes = () => {
  const { cart, setCart } = useOutletContext<ChannelsContext>();

  const { t } = useTranslation("components/notes");
  const { user } = useAuth();

  const [newNote, setNewNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const addNote = async (
    e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();
    if (!cart) {
      return;
    }

    if (!newNote || newNote.trim().length === 0) {
      setError("note_required");
      setNewNote("");
      return;
    }

    try {
      setError(null);
      setLoading(true);

      const response = await Api.post(`/cart/${cart.cartid}/save`, {
        reference: cart.reference,
        note: newNote.trim(),
      });

      setCart(response.cart);
      setNewNote("");
    } catch (error) {
      console.error(error);
      setError("something_went_wrong");
    } finally {
      setLoading(false);
    }
  };

  if (!cart) return null;

  return (
    <div className={styles.notes}>
      <Text variant="h4">{t("title")}</Text>
      {(!cart.notes || cart.notes.length === 0) && (
        <Text variant="p">{t("no_notes")}</Text>
      )}
      {cart.notes && cart.notes.length > 0 && (
        <>
          <ul className={styles.notes__list}>
            {cart.notes.map((note) => {
              return (
                <li key={note.id} className={styles.notes_listItem}>
                  <Text variant="p" noMargin>
                    {note.note}
                  </Text>
                  <Text variant="small" noMargin>
                    {`${note.user.firstname} ${
                      note.user.lastname
                    } - ${formatDateTime(
                      note.created_at,
                      user?.language ?? "nl"
                    )}`}
                  </Text>
                </li>
              );
            })}
          </ul>
        </>
      )}
      <div className={styles.notes__newNote}>
        <Text variant="h6">{t("add_note.title")}</Text>
        <Form onSubmit={addNote} loading={loading}>
          {error && <Alert type="error" message={t(`errors.${error}`)} />}
          <FormGroup>
            <FormTextArea
              name="note"
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
            />
          </FormGroup>
          <Button onClick={addNote}>{t("add_note.submit")}</Button>
        </Form>
      </div>
    </div>
  );
};
