import { useCallback, useEffect, useState } from "react";
import {
  Container,
  PageHeader,
  Table,
  TableHeadCell,
  TableHead,
  TableRow,
  UIPage,
  TableBody,
  Button,
  TableCell,
  Text,
  useAuth,
  Pagination,
  FilterBar,
  FilterCheckbox,
  Empty,
  Badge,
  FilterSearch,
} from "@eventsquare/uikit";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  RiDeleteBinLine,
  RiFileList2Line,
  RiLockStarLine,
} from "@remixicon/react";

import { Api } from "@lib/api";
import { formatCurrency, formatDateTime, getUserName } from "@lib/helpers";
import { getLocalStorage, setLocalStorage } from "@lib/localStorage";

import { Cart } from "@type/cart";

import { DeleteCartModal } from "@components/modals/DeleteCartModal/DeleteCartModal";
import { PageLoader } from "@components/PageLoader/PageLoader";
import { NotesModal } from "@components/modals/NotesModal/NotesModal";
import { Currency } from "@type/currency";

interface FetchParams {
  search?: string;
  limit?: number;
  page?: number;
  expired?: 0 | 1; // 0 show only not expired, 1 show only expired, omit param to show all
}

const CARTS_PER_PAGE = 25;

const getInitialHideExpiredCartsStatus = (): boolean => {
  const hideExpiredCarts = getLocalStorage("hide_expired_options");
  if (hideExpiredCarts === "false") {
    return false;
  } else {
    return true;
  }
};

export const Carts = () => {
  const [carts, setCarts] = useState<Cart[] | null>(null);
  const [cartToDelete, setCartToDelete] = useState<{
    cartid: string;
    saved_at: string | null;
  } | null>(null);
  const [cartWithDetails, setCartWithDetails] = useState<Cart | null>(null);
  const [hideExpiredCarts, setHideExpiredCarts] = useState<boolean>(
    getInitialHideExpiredCartsStatus()
  );
  const [search, setSearch] = useState<string>("");
  const [cartSearch, setCartSearch] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);
  const [cartsTotal, setCartsTotal] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const { accountId, account, user } = useAuth();
  const { t } = useTranslation(["pages/carts", "components/pagination"]);
  const navigate = useNavigate();

  const fetchCarts = useCallback(async () => {
    setLoading(true);

    const params: FetchParams = {
      //search: "string",
      limit: CARTS_PER_PAGE,
      page: activePage,
      //expired: 1,
    };

    if (hideExpiredCarts) {
      params.expired = 0;
    }

    if (cartSearch.trim().length > 0) {
      params.search = cartSearch;
    }

    try {
      const data = await Api.get(`/accounts/${accountId}/pos/carts`, params);
      setCarts(data.carts);
      setCartsTotal(data.meta.pagination.total);
    } catch (error) {
      if (Api.isApiError(error)) {
        //console.log(error.response);
      }
    } finally {
      setLoading(false);
    }
  }, [accountId, hideExpiredCarts, cartSearch, activePage]);

  useEffect(() => {
    fetchCarts();
  }, [fetchCarts]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setCartSearch(search);
      setActivePage(1);
    }, 500);
    return () => {
      clearTimeout(searchTimeout);
    };
  }, [search]);

  const togglehideExpiredCarts = () => {
    const newState = !hideExpiredCarts;
    setHideExpiredCarts(newState);
    setActivePage(1);
    setLocalStorage("hide_expired_options", newState.toString());
  };

  const navTo = (url: string) => {
    navigate(url);
  };

  return (
    <UIPage>
      <PageHeader title={t("title")} badge={cartsTotal || undefined} />
      <Container>
        <FilterBar>
          <FilterSearch
            value={search}
            setValue={setSearch}
            placeholder={t("filter.search")}
          />
          <FilterCheckbox
            label={t("filter.hide_expired")}
            checked={hideExpiredCarts}
            onChange={togglehideExpiredCarts}
          />
        </FilterBar>
        {loading && <PageLoader />}
        {!loading && carts && carts.length === 0 && (
          <Empty
            title={t("empty.title")}
            lead={t("empty.lead")}
            icon={<RiLockStarLine />}
          />
        )}
        {!loading && carts && carts.length > 0 && (
          <>
            <Table boxed>
              <TableHead>
                <TableRow>
                  <TableHeadCell>{t("reference")}</TableHeadCell>
                  <TableHeadCell>{t("event")}</TableHeadCell>
                  <TableHeadCell>{t("price")}</TableHeadCell>
                  <TableHeadCell>{t("status")}</TableHeadCell>
                  <TableHeadCell>{t("expires_at")}</TableHeadCell>
                  <TableHeadCell>{t("created_at")}</TableHeadCell>
                  <TableHeadCell>{t("created_by")}</TableHeadCell>
                  <TableHeadCell></TableHeadCell>
                  <TableHeadCell></TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carts.map((cart, i) => {
                  return (
                    <TableRow
                      key={`${i}_${cart.cartid}`}
                      onClick={
                        cart.expired
                          ? undefined
                          : () => {
                              navTo(
                                `/channel/${cart.channel.id}?cart=${cart.cartid}`
                              );
                            }
                      }
                    >
                      <TableCell noWrap>
                        <Text variant="h6" component="span" noMargin>
                          {cart.expired ? (
                            cart.reference
                          ) : (
                            <Link
                              to={`/channel/${cart.channel.id}?cart=${cart.cartid}`}
                            >
                              {cart.reference}
                            </Link>
                          )}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {`${cart.event.name}${
                            cart.edition.name ? ` - ${cart.edition.name}` : ""
                          }`}
                        </Text>
                        <Text variant="small" component="span" noMargin>
                          {cart.channel.name}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {formatCurrency(
                            cart.summary.total,
                            account?.currency as Currency
                          )}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        {cart.expired ? (
                          <Badge
                            size="small"
                            content={t("status_badge.expired")}
                            color="error"
                          />
                        ) : (
                          <Badge
                            size="small"
                            content={t("status_badge.open")}
                            color="success"
                          />
                        )}
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {cart.expires_at
                            ? formatDateTime(
                                cart.expires_at,
                                user?.language ?? "nl"
                              )
                            : "-"}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {cart.saved_at
                            ? formatDateTime(
                                cart.saved_at,
                                user?.language ?? "nl"
                              )
                            : "-"}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {getUserName(cart.user)}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        {cart.notes.length >= 1 && (
                          <Button
                            icon
                            onClick={(e) => {
                              if (e) e.stopPropagation();
                              setCartWithDetails(cart);
                            }}
                            variant="outline"
                            size="tiny"
                            color="primary"
                          >
                            <RiFileList2Line />
                          </Button>
                        )}
                      </TableCell>
                      <TableCell noWrap>
                        <Button
                          icon
                          onClick={(e) => {
                            if (e) e.stopPropagation();
                            setCartToDelete({
                              cartid: cart.cartid,
                              saved_at: cart.saved_at,
                            });
                          }}
                          variant="outline"
                          size="tiny"
                          color="error"
                        >
                          <RiDeleteBinLine />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Pagination
              activePage={activePage}
              setActivePage={setActivePage}
              itemsPerPage={CARTS_PER_PAGE}
              itemsTotal={cartsTotal ?? 0}
              translations={{
                first_page: t("components/pagination:first_page"),
                last_page: t("components/pagination:last_page"),
                active_page: t("components/pagination:active_page"),
              }}
            />
          </>
        )}
      </Container>
      <DeleteCartModal
        cart={cartToDelete}
        setCartNull={() => setCartToDelete(null)}
        afterDelete={fetchCarts}
      />
      <NotesModal
        notes={cartWithDetails?.notes ?? null}
        closeModal={() => setCartWithDetails(null)}
        title={cartWithDetails?.reference ?? undefined}
      />
    </UIPage>
  );
};
