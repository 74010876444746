import styles from "./CheckoutOnlineForm.module.scss";
import { useState } from "react";
import {
  Alert,
  Button,
  Empty,
  Form,
  FormCheckbox,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  FormTextArea,
  Text,
  useAuth,
} from "@eventsquare/uikit";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ChannelCart } from "@type/channelCart";

import { Api } from "@lib/api";
import { removeCookie } from "@lib/cookies";
import { RiMailSendLine } from "@remixicon/react";
import { hasSeatmapTypes } from "@lib/helpers";
import { getLocalStorage, setLocalStorage } from "@lib/localStorage";

interface CheckoutOnlineFormProps {
  cart: ChannelCart;
  setShowSwitch: (show: boolean) => void;
  languages: { value: string; label: string }[];
}

interface FormValuesOnline {
  email: string;
  reference: string;
  message: string;
  language: "nl" | "fr" | "en";
  validity_period: string;
  lock_places: boolean;
  note: string;
}

interface Error {
  response: {
    body: {
      message: string;
      error: string;
      validation: {
        cart?: string[];
        email?: string[];
        language?: string[];
        validity_period?: string[];
      };
    };
  };
}

const getInitialValidtyPeriod = (): string => {
  const savedValidityPeriod = getLocalStorage("invitation_validity_period");
  if (savedValidityPeriod) {
    return savedValidityPeriod;
  } else {
    return "43800";
  }
};

export const CheckoutOnlineForm = (props: CheckoutOnlineFormProps) => {
  const { cart, setShowSwitch, languages } = props;
  const { accountId } = useAuth();
  const { t } = useTranslation(["components/checkout_online_form", "common"]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValuesOnline>({
    email: "",
    reference: "",
    message: "",
    language: "nl",
    validity_period: getInitialValidtyPeriod(),
    lock_places: false,
    note: "",
  });
  const [step, setStep] = useState<"form" | "success">("form");
  const [errors, setErrors] = useState<string[] | null>(null);

  const handleSubmit = async (
    e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    if (e) e.preventDefault();

    try {
      setLoading(true);
      setErrors(null);

      const data: {
        cart: string;
        email: string;
        reference: string;
        message: string;
        validity_period: string | null;
        language: string;
        lock_places: 1 | 0;
        note?: string;
      } = {
        cart: cart.cartid,
        email: formValues.email.trim(),
        reference: formValues.reference.trim(),
        message: formValues.message.trim(),
        validity_period:
          formValues.validity_period === "never"
            ? null
            : formValues.validity_period,
        language: formValues.language,
        lock_places: formValues.lock_places ? 1 : 0,
      };

      if (formValues.note.trim() !== "") {
        data.note = formValues.note.trim();
      }

      const response = await Api.post(
        `/accounts/${accountId}/pos/reservations`,
        data
      );

      if (response) {
        setShowSwitch(false);
        removeCookie();
        setStep("success");
      }
    } catch (error) {
      if (Api.isApiError(error)) {
        //console.log(error.response);
      }
      const err = (error as Error).response?.body;
      if (err.error === "validation_failed") {
        const validationErrors: string[] = [];
        for (const [key, value] of Object.entries(err.validation)) {
          value.forEach((v: string) => {
            validationErrors.push(`${key}_${v}`);
          });
        }
        setErrors(validationErrors);
      } else {
        setErrors([err?.error ?? "something_went_wrong"]);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    if (name === "validity_period") {
      setLocalStorage("invitation_validity_period", value);
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const toggleLockPlaces = () => {
    setFormValues({ ...formValues, lock_places: !formValues.lock_places });
  };

  const finishCheckout = async () => {
    await removeCookie();
    navigate("/");
  };

  return (
    <div className={styles.checkoutOnlineForm}>
      {step === "form" && (
        <>
          <Text variant="h5">{t("form.title")}</Text>
          <Text variant="p">{t("form.lead")}</Text>
          <Form onSubmit={handleSubmit} loading={loading}>
            {errors && errors.length >= 1 && (
              <Alert
                type="error"
                message={errors.map((error) => t(`errors.${error}`))}
              />
            )}
            <FormGroup>
              <FormLabel htmlFor="firstname">{t("form.email")}</FormLabel>
              <FormInput
                name="email"
                type="email"
                value={formValues.email}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="reference">{t("form.reference")}</FormLabel>
              <FormInput
                name="reference"
                type="text"
                value={formValues.reference}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="message" optional={t("common:optional")}>
                {t("form.message")}
              </FormLabel>
              <FormTextArea
                name="message"
                value={formValues.message}
                onChange={handleChange}
                rows={5}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="lang">{t("form.lang")}</FormLabel>
              <FormSelect
                name="language"
                value={formValues.language}
                onChange={handleChange}
                placeholder={t("form.lang_placeholder")}
                options={languages.sort((a, b) => (a.label < b.label ? -1 : 1))}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="validity_period">
                {t("form.validity")}
              </FormLabel>
              <FormSelect
                name="validity_period"
                value={formValues.validity_period}
                onChange={handleChange}
                placeholder={t("form.validity_placeholder")}
                options={[
                  { value: "4320", label: t("form.validity_3_days") },
                  { value: "10080", label: t("form.validity_1_week") },
                  { value: "43800", label: t("form.validity_1_month") },
                  { value: "262800", label: t("form.validity_6_months") },
                  { value: "525600", label: t("form.validity_1_year") },
                  { value: "1576800", label: t("form.validity_3_years") },
                  { value: "never", label: t("form.validity_never") },
                ]}
              />
            </FormGroup>
            {hasSeatmapTypes(cart) && (
              <FormGroup>
                <FormCheckbox
                  name="lock_places"
                  label={t("form.lock_places")}
                  checked={!formValues.lock_places}
                  onChange={toggleLockPlaces}
                />
              </FormGroup>
            )}
            <FormGroup>
              <FormLabel htmlFor="note" optional={t("common:optional")}>
                {t("form.note")}
              </FormLabel>
              <FormTextArea
                name="note"
                value={formValues.note}
                onChange={handleChange}
              />
            </FormGroup>
            <Button block onClick={handleSubmit}>
              {t("form.submit")}
            </Button>
          </Form>
        </>
      )}
      {step === "success" && (
        <>
          <Empty
            icon={<RiMailSendLine />}
            title={t("success.title")}
            lead={t("success.lead", { email: formValues.email })}
          >
            <Button onClick={finishCheckout}>
              {t("success.finish_checkout")}
            </Button>
          </Empty>
        </>
      )}
    </div>
  );
};
