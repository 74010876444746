import Cookies, { CookieSetOptions } from "universal-cookie";

export interface CookiePOS {
  cart_id?: string;
  channel_id?: string;
  edition_name?: string | null;
  event_name?: string;
  pos_token?: string;
  reference?: string | null;
  saved_at?: string | null;
}

const cookieName = import.meta.env.VITE_COOKIE_NAME ?? "_esq_pos";

export const getCookie = () => {
  const cookies = new Cookies();
  const cookie = cookies.get(cookieName);
  return cookie as CookiePOS | null;
};

export const setCookie = (cookie: CookiePOS, options?: CookieSetOptions) => {
  const cookies = new Cookies();
  cookies.set(cookieName, cookie, {
    domain: import.meta.env.VITE_COOKIE_DOMAIN,
    path: "/",
    ...options,
  });
  return true;
};

export const removeCookie = () => {
  const cookies = new Cookies();
  cookies.remove(cookieName, {
    domain: import.meta.env.VITE_COOKIE_DOMAIN,
    path: "/",
  });
  return true;
};
