import styles from "./Shows.module.scss";
import { Text } from "@eventsquare/uikit";

import { ShowsList } from "@components/ShowsList/ShowsList";

import { ShowMode } from "@type/show";
import { ChannelShow } from "@type/channelEdition";
import { useTranslation } from "react-i18next";

interface ShowsProps {
  shows: ChannelShow[];
  showMode: ShowMode;
  title?: string;
  setActiveShowId: (showId: string) => void;
}

export const Shows = (props: ShowsProps) => {
  const { shows, showMode, title, setActiveShowId } = props;

  const { t } = useTranslation("components/shows");

  if (!shows.length) return null;

  // depending on showmode we display a list or a calendar
  return (
    <div className={styles.shows}>
      <Text variant="h4">{title ?? t("shows_title")}</Text>
      {showMode === "list" && (
        <ShowsList shows={shows} setActiveShowId={setActiveShowId} />
      )}
    </div>
  );
};
