import classNames from "classnames/bind";
import styles from "./CheckoutSwitch.module.scss";
import { Text } from "@eventsquare/uikit";
import { useTranslation } from "react-i18next";

interface CheckoutSwitchProps {
  paymentType: "direct" | "online";
  setPaymentType: (type: "direct" | "online") => void;
  cartTotal: number;
}

const cx = classNames.bind(styles);

export const CheckoutSwitch = (props: CheckoutSwitchProps) => {
  const { paymentType, setPaymentType, cartTotal } = props;
  const { t } = useTranslation("components/checkout_switch");

  const directClass = cx("checkoutSwitch__button", {
    "checkoutSwitch__button--active": paymentType === "direct",
  });

  const onlineClass = cx("checkoutSwitch__button", {
    "checkoutSwitch__button--active": paymentType === "online",
  });

  return (
    <div className={styles.checkoutSwitch}>
      <div className={directClass} onClick={() => setPaymentType("direct")}>
        <Text variant="h6">{t("direct.title")}</Text>
        <Text variant="p" noMargin>
          {t("direct.lead")}
        </Text>
      </div>
      <div className={onlineClass} onClick={() => setPaymentType("online")}>
        <Text variant="h6">
          {t(cartTotal > 0 ? "online.paylink_title" : "online.free_title")}
        </Text>
        <Text variant="p" noMargin>
          {t(cartTotal > 0 ? "online.paylink_lead" : "online.free_lead")}
        </Text>
      </div>
    </div>
  );
};
