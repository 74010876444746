import { Note } from "@type/note";
import styles from "./NotesModal.module.scss";
import { Button, Modal, ModalButtons, Text, useAuth } from "@eventsquare/uikit";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "@lib/helpers";

interface NotesModalProps {
  notes: Note[] | null;
  closeModal: () => void;
  title?: string;
}

export const NotesModal = (props: NotesModalProps) => {
  const { notes, closeModal, title } = props;
  const { user } = useAuth();

  const { t } = useTranslation("components/notes_modal");

  return (
    <Modal isVisible={!!notes} closeModal={closeModal}>
      <div className={styles.notesModal}>
        <Text variant="h3" color="info">
          {title ?? t("title")}
        </Text>
        {notes && notes.length === 0 && (
          <Text variant="p">{t("no_notes")}</Text>
        )}
        {notes &&
          notes.length >= 1 &&
          notes.map((note, index) => (
            <div key={index} className={styles.note}>
              <Text variant="p" noMargin>
                {note.note}
              </Text>
              <Text variant="small">
                {`${note.user.firstname} ${
                  note.user.lastname
                } - ${formatDateTime(note.created_at, user?.language ?? "nl")}`}
              </Text>
            </div>
          ))}
      </div>
      <ModalButtons>
        <Button onClick={closeModal}>{t("close")}</Button>
      </ModalButtons>
    </Modal>
  );
};
