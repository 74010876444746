import styles from "./HasCartModal.module.scss";
import { Button, Text, Modal, ModalButtons, Alert } from "@eventsquare/uikit";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CookiePOS, removeCookie } from "@lib/cookies";
import { Api } from "@lib/api";

import { ChannelsContext } from "@components/ChannelsWrapper";

import { RiShoppingCartLine } from "@remixicon/react";

interface HasCartModalProps {
  cookie: CookiePOS | null;
  closeModal: () => void;
}

export const HasCartModal = (props: HasCartModalProps) => {
  const { cookie, closeModal } = props;
  const { setCart } = useOutletContext<ChannelsContext>();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation("components/has_cart_modal");

  const getEventName = () => {
    if (!cookie) return "";
    return `${cookie.event_name!} ${cookie.edition_name ?? ""}`.trim();
  };

  const goToOpenSession = () => {
    if (!cookie) return;

    closeModal();
    navigate(`/channel/${cookie.channel_id}`);
  };

  const deleteCart = async () => {
    if (!cookie) return;
    setError(null);
    setLoading(true);
    try {
      const { cart_id } = cookie;

      if (!cart_id) {
        await removeCookie();
        return;
      }

      await Api.delete(`/cart/${cart_id}`);
      await removeCookie();
      setCart(null);
      closeModal();
    } catch (error) {
      if (
        Api.isApiError(error) &&
        (error.response as { body: { error: string } })?.body?.error ===
          "cart_not_found"
      ) {
        await removeCookie();
      } else {
        setError("something_went_wrong");
        // console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  // if cookie has saved_at it is an option so delete the cookie and open the selected option

  if (!cookie) return null;

  return (
    <Modal isVisible={!!cookie} icon={RiShoppingCartLine} iconColor="error">
      <div className={styles.hasCartModal}>
        <div className={styles.hasCartModal__info}>
          <Text variant="h3" color="error">
            {t("title")}
          </Text>
          <Text variant="p">{t("lead", { eventName: getEventName() })}</Text>
          {error && <Alert type="error" message={t(`errors.${error}`)} />}
        </div>
        <ModalButtons>
          <Button block onClick={deleteCart} color="error" loading={loading}>
            {t("delete_cart")}
          </Button>
          <Button
            block
            onClick={goToOpenSession}
            variant="link"
            color="subtle"
            disabled={loading}
          >
            {t("go_to_cart")}
          </Button>
        </ModalButtons>
      </div>
    </Modal>
  );
};
