import { useState } from "react";
import styles from "./Checkout.module.scss";
import { useOutletContext } from "react-router-dom";

import { ChannelsContext } from "@components/ChannelsWrapper";
import { CheckoutDirectForm } from "@components/CheckoutDirectForm/CheckoutDirectForm";
import { CheckoutOnlineForm } from "@components/CheckoutOnlineForm/CheckoutOnlineForm";
import { CheckoutSwitch } from "@components/CheckoutSwitch/CheckoutSwitch";
import { Button, ButtonGroup, Empty } from "@eventsquare/uikit";

import { ChannelStep } from "@type/channel";
import { useTranslation } from "react-i18next";
import { RiShoppingCartLine } from "@remixicon/react";

interface CheckoutProps {
  setStep: (step: ChannelStep) => void;
}

export const Checkout = (props: CheckoutProps) => {
  const { setStep } = props;
  const { cart, customerLanguages } = useOutletContext<ChannelsContext>();
  const { t } = useTranslation("components/checkout");

  const [paymentType, setPaymentType] = useState<"direct" | "online">("direct");
  const [showSwitch, setShowSwitch] = useState<boolean>(true);

  const getLanguages = () => {
    return customerLanguages.map((lang) => {
      return {
        value: lang.code,
        label: t(`languages.${lang.code}`),
      };
    });
  };

  const goToStore = () => {
    setStep("store");
  };

  if (!cart) return null;

  return (
    <div className={styles.checkout}>
      {cart.items.types.length < 1 && (
        <Empty
          icon={<RiShoppingCartLine />}
          title={t("empty_title")}
          lead={t("empty_lead")}
        >
          <ButtonGroup noMargin>
            <Button variant="link" onClick={goToStore}>
              {t("empty_to_store")}
            </Button>
          </ButtonGroup>
        </Empty>
      )}
      {cart.items.types.length >= 1 && (
        <div className={styles.checkout__form}>
          {showSwitch && (
            <CheckoutSwitch
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              cartTotal={cart.summary.total}
            />
          )}
          {paymentType === "direct" ? (
            <CheckoutDirectForm
              cart={cart}
              setShowSwitch={setShowSwitch}
              languages={getLanguages()}
            />
          ) : null}
          {paymentType === "online" ? (
            <CheckoutOnlineForm
              cart={cart}
              setShowSwitch={setShowSwitch}
              languages={getLanguages()}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
