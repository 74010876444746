import { useCallback, useEffect, useState } from "react";
import {
  Container,
  PageHeader,
  Table,
  TableHeadCell,
  TableHead,
  TableRow,
  UIPage,
  TableBody,
  Button,
  TableCell,
  Text,
  useAuth,
  Badge,
  Pagination,
  FilterBar,
  FilterCheckbox,
  Empty,
  FilterSearch,
} from "@eventsquare/uikit";
import {
  RiDeleteBinLine,
  RiFileList2Line,
  RiMailSendLine,
} from "@remixicon/react";
import { useTranslation } from "react-i18next";

import { formatCurrency, formatDateTime, getUserName } from "@lib/helpers";
import { Api } from "@lib/api";
import { getLocalStorage, setLocalStorage } from "@lib/localStorage";

import { Invitation, InvitationStatus } from "@/types/invitation";

import { DeleteInvitationModal } from "@components/modals/DeleteInvitationModal/DeleteInvitationModal";
import { PageLoader } from "@components/PageLoader/PageLoader";
import { NotesModal } from "@components/modals/NotesModal/NotesModal";
import { Currency } from "@type/currency";

interface FetchParams {
  search?: string;
  limit?: number;
  page?: number;
  hide_expired?: boolean; //when true, only non-expired invitations are returned
}

const INVITATIONS_PER_PAGE = 25;

const getInitialHideExpiredInvitationsStatus = (): boolean => {
  const hideExpiredInvitations = getLocalStorage("hide_expired_invitations");
  if (hideExpiredInvitations === "false") {
    return false;
  } else {
    return true;
  }
};

export const Invitations = () => {
  const [invitations, setInvitations] = useState<Invitation[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [hideExpiredInvitations, setHideExpiredInvitations] = useState<boolean>(
    getInitialHideExpiredInvitationsStatus()
  );
  const [search, setSearch] = useState<string>("");
  const [invitationSearch, setInvitationSearch] = useState<string>("");
  const [invitationToDelete, setInvitationToDelete] =
    useState<Invitation | null>(null);
  const [invitationWithNotes, setInvitationWithNotes] =
    useState<Invitation | null>(null);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalInvitations, setTotalInvitations] = useState<number | undefined>(
    undefined
  );
  const { accountId, account, user } = useAuth();
  const { t } = useTranslation(["pages/invitations", "components/pagination"]);

  const fetchInvitations = useCallback(async () => {
    setLoading(true);

    const params: FetchParams = {
      //search: "test",
      limit: INVITATIONS_PER_PAGE,
      page: activePage,
      //expired: 1,
    };

    if (hideExpiredInvitations) {
      params.hide_expired = true;
    }

    if (invitationSearch.trim().length > 0) {
      params.search = invitationSearch.trim();
    }

    try {
      const data = await Api.get(
        `/accounts/${accountId}/pos/invitations`,
        params
      );
      setInvitations(data.invitations);
      setTotalInvitations(data.meta.pagination.total);
    } catch (error) {
      if (Api.isApiError(error)) {
        //console.log(error.response);
      }
    } finally {
      setLoading(false);
    }
  }, [accountId, hideExpiredInvitations, invitationSearch, activePage]);

  useEffect(() => {
    fetchInvitations();
  }, [fetchInvitations]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setInvitationSearch(search);
      setActivePage(1);
    }, 500);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [search]);

  const togglehideExpiredInvitations = () => {
    const newState = !hideExpiredInvitations;
    setHideExpiredInvitations(newState);
    setActivePage(1);
    setLocalStorage("hide_expired_invitations", newState.toString());
  };

  const getInvitationStatusBadgeColor = (status: InvitationStatus) => {
    switch (status) {
      case "created":
      case "processed":
      case "opened":
        return "info";
      case "accepted":
        return "success";
      case "failed":
      case "expired":
        return "error";
    }
  };

  return (
    <UIPage>
      <PageHeader title={t("title")} badge={totalInvitations || undefined} />
      <Container>
        <FilterBar>
          <FilterSearch
            value={search}
            setValue={setSearch}
            placeholder={t("filter.search")}
          />
          <FilterCheckbox
            label={t("filter.hide_expired")}
            checked={hideExpiredInvitations}
            onChange={togglehideExpiredInvitations}
          />
        </FilterBar>
        {loading && <PageLoader />}
        {!loading && invitations && invitations.length === 0 && (
          <Empty
            title={t("empty.title")}
            lead={t("empty.lead")}
            icon={<RiMailSendLine />}
          />
        )}
        {!loading && invitations && invitations.length > 0 && (
          <>
            <Table boxed>
              <TableHead>
                <TableRow>
                  <TableHeadCell>{t("reference")}</TableHeadCell>
                  <TableHeadCell>{t("email")}</TableHeadCell>
                  <TableHeadCell>{t("event")}</TableHeadCell>
                  <TableHeadCell>{t("price")}</TableHeadCell>
                  <TableHeadCell>{t("status")}</TableHeadCell>
                  <TableHeadCell>{t("created_at")}</TableHeadCell>
                  <TableHeadCell>{t("created_by")}</TableHeadCell>
                  <TableHeadCell></TableHeadCell>
                  <TableHeadCell></TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invitations.map((invitation) => {
                  return (
                    <TableRow key={invitation.id}>
                      <TableCell noWrap>
                        <Text variant="h6" component="span" noMargin>
                          {invitation.reference}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {invitation.email}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {`${invitation.event.name}${
                            invitation.edition.name
                              ? ` - ${invitation.edition.name}`
                              : ""
                          }`}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {invitation.price
                            ? formatCurrency(
                                invitation.price,
                                account?.currency as Currency
                              )
                            : t(`free`)}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        <Badge
                          size="small"
                          content={t(`status_badge.${invitation.status}`)}
                          color={getInvitationStatusBadgeColor(
                            invitation.status
                          )}
                        />
                        {}
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {invitation.created_at
                            ? formatDateTime(
                                invitation.created_at,
                                user?.language ?? "nl"
                              )
                            : "-"}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {getUserName(invitation.user)}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        {!!invitation.cart &&
                          invitation.cart.notes.length >= 1 && (
                            <Button
                              icon
                              variant="outline"
                              size="tiny"
                              color="primary"
                              onClick={(e) => {
                                if (e) e.stopPropagation();
                                setInvitationWithNotes(invitation);
                              }}
                            >
                              <RiFileList2Line />
                            </Button>
                          )}
                      </TableCell>
                      <TableCell noWrap>
                        {!invitation?.reserved_at && (
                          <Button
                            icon
                            variant="outline"
                            size="tiny"
                            color="error"
                            onClick={(e) => {
                              if (e) e.stopPropagation();
                              setInvitationToDelete(invitation);
                            }}
                          >
                            <RiDeleteBinLine />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Pagination
              activePage={activePage}
              setActivePage={setActivePage}
              itemsTotal={totalInvitations ?? 0}
              itemsPerPage={INVITATIONS_PER_PAGE}
              translations={{
                first_page: t("components/pagination:first_page"),
                last_page: t("components/pagination:last_page"),
                active_page: t("components/pagination:active_page"),
              }}
            />
          </>
        )}
      </Container>
      <DeleteInvitationModal
        invitation={invitationToDelete}
        setInvitationNull={() => {
          setInvitationToDelete(null);
        }}
        refetchInvitations={fetchInvitations}
      />
      <NotesModal
        notes={invitationWithNotes?.cart.notes ?? null}
        closeModal={() => setInvitationWithNotes(null)}
        title={invitationWithNotes?.reference ?? undefined}
      />
    </UIPage>
  );
};
