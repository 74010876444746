import styles from "./CheckoutDirectForm.module.scss";
import { useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Empty,
  Form,
  FormCheckbox,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  FormTextArea,
  Text,
  useAuth,
} from "@eventsquare/uikit";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiShoppingCartLine } from "@remixicon/react";

import { ChannelCart } from "@type/channelCart";

import { formatCurrency, hasSeatmapTypes } from "@lib/helpers";
import { Api } from "@lib/api";
import { removeCookie } from "@lib/cookies";
import { Currency } from "@type/currency";

interface CheckoutDirectFormProps {
  cart: ChannelCart;
  setShowSwitch: (show: boolean) => void;
  languages: { value: string; label: string }[];
}

interface FormValuesDirect {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  language: "nl" | "fr" | "en";
  lock_places: boolean;
  note: string;
}
//TODO : get proper typing for Order
interface Order {
  data: {
    pockets: {
      url: string;
    }[];
  };
}

export const CheckoutDirectForm = (props: CheckoutDirectFormProps) => {
  const { cart, setShowSwitch, languages } = props;

  const navigate = useNavigate();
  const { t } = useTranslation(["components/checkout_direct_form", "common"]);
  const { account } = useAuth();

  const [step, setStep] = useState<
    "form" | "confirm" | "processing" | "success"
  >("form");
  const [order, setOrder] = useState<Order | null>(null);
  const [errors, setErrors] = useState<string | null>(null);
  const [formValues, setFormValues] = useState<FormValuesDirect>({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    language: "nl",
    lock_places: false,
    note: "",
  });

  const handleSubmit = (
    e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    if (e) e.preventDefault();
    setShowSwitch(false);
    setErrors(null);
    setStep("confirm");
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const toggleLockPlaces = () => {
    setFormValues({ ...formValues, lock_places: !formValues.lock_places });
  };

  const confirmPayment = async () => {
    setErrors(null);

    const data = {
      invoice: 0,
      redirecturl: "http://www.eventsquare.io",
      testmode: 0,
      customer: {
        firstname: formValues.firstname,
        lastname: formValues.lastname,
        email: formValues.email,
        telephone: formValues.phone,
        language: formValues.language,
      },
      lock_places: formValues.lock_places ? 1 : 0,
      note: formValues.note.trim() !== "" ? formValues.note.trim() : undefined,
    };

    try {
      const response = await Api.post(`/cart/${cart.cartid}`, data);

      if (response.order) {
        setStep("processing");
        waitForOrderToBeProcessed(response.order.orderid);
      }
    } catch (error) {
      setStep("form");
      setShowSwitch(true);
      setErrors("something_went_wrong");
      console.error(error);
    }
  };

  const waitForOrderToBeProcessed = async (orderId: string) => {
    try {
      const response = await Api.get(`/checkout/${orderId}`);
      if (response.order.action === "processing") {
        setTimeout(() => {
          waitForOrderToBeProcessed(orderId);
        }, 500);
      } else {
        removeCookie();
        setOrder(response.order);
        setStep("success");
      }
    } catch (error) {
      setStep("form");
      setShowSwitch(true);
      setErrors("something_went_wrong");
      console.error(error);
    }
  };

  const downloadTickets = async () => {
    if (!order) return;
    console.log(order.data.pockets[0].url);
    window.open(order.data.pockets[0].url + "/tickets");
  };

  const closeCheckout = async () => {
    await removeCookie();
    navigate("/");
  };

  return (
    <div className={styles.checkoutDirectForm}>
      {step === "form" && (
        <>
          <Text variant="h5">{t("form.title")}</Text>
          <Text variant="p">{t("form.lead")}</Text>
          <Form onSubmit={handleSubmit} loading={false}>
            {errors && <Alert type="error" message={t(`errors.${errors}`)} />}
            <FormGroup>
              <FormLabel htmlFor="firstname" optional={t("common:optional")}>
                {t("form.firstname")}
              </FormLabel>
              <FormInput
                name="firstname"
                type="text"
                value={formValues.firstname}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="lastname" optional={t("common:optional")}>
                {t("form.lastname")}
              </FormLabel>
              <FormInput
                name="lastname"
                type="text"
                value={formValues.lastname}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="email" optional={t("common:optional")}>
                {t("form.email")}
              </FormLabel>
              <FormInput
                name="email"
                type="email"
                value={formValues.email}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="phone" optional={t("common:optional")}>
                {t("form.phone")}
              </FormLabel>
              <FormInput
                name="phone"
                type={"tel" as unknown as "text"}
                value={formValues.phone}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="lang">{t("form.language")}</FormLabel>
              <FormSelect
                placeholder={t("form.lang_placeholder")}
                name="language"
                value={formValues.language}
                onChange={handleChange}
                options={languages.sort((a, b) => (a.label < b.label ? -1 : 1))}
              />
            </FormGroup>
            {hasSeatmapTypes(cart) && (
              <FormGroup>
                <FormCheckbox
                  name="lock_places"
                  label={t("form.lock_places")}
                  checked={!formValues.lock_places}
                  onChange={toggleLockPlaces}
                />
              </FormGroup>
            )}
            <FormGroup>
              <FormLabel htmlFor="note" optional={t("common:optional")}>
                {t("form.note")}
              </FormLabel>
              <FormTextArea
                name="note"
                value={formValues.note}
                onChange={handleChange}
              />
            </FormGroup>
            <Button block onClick={handleSubmit}>
              {t("form.submit")}
            </Button>
          </Form>
        </>
      )}
      {step === "confirm" && (
        <>
          <Empty
            icon={<RiShoppingCartLine />}
            title={t("confirmation.title")}
            lead={t("confirmation.lead", {
              amount: formatCurrency(
                cart.summary.total,
                account?.currency as Currency
              ),
            })}
          >
            <Button onClick={() => confirmPayment()}>
              {t("confirmation.confirm")}
            </Button>
          </Empty>
        </>
      )}
      {step === "processing" && <Text variant="h5">{t("processing")}</Text>}
      {step === "success" && (
        <>
          <Text variant="h5">{t("success.title")}</Text>
          <ButtonGroup>
            <Button onClick={downloadTickets}>
              {t("success.download_tickets")}
            </Button>
            <Button onClick={closeCheckout}>
              {t("success.finish_checkout")}
            </Button>
          </ButtonGroup>
        </>
      )}
    </div>
  );
};
