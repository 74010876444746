import styles from "./Tickets.module.scss";
import { useState } from "react";
import { Badge, Button, Text, useAuth } from "@eventsquare/uikit";
import { RiAddLine } from "@remixicon/react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import { ChannelType, ActiveChannelType } from "@type/channelEdition";

import { formatCurrency, getBadgeColorFromStatus } from "@lib/helpers";
import { EditTypeModal } from "@components/modals/EditTypeModal/EditTypeModal";
import { Currency } from "@type/currency";

const cx = classNames.bind(styles);

interface TicketsProps {
  types: ChannelType[];
  title?: string | null | undefined;
  showId?: string;
  seatmapId?: string;
}

export const Tickets = (props: TicketsProps) => {
  const { types, title, showId, seatmapId } = props;
  const [activeType, setActiveType] = useState<ActiveChannelType | null>(null);

  const { t } = useTranslation("components/tickets");

  const { account } = useAuth();

  const editType = (type: ChannelType) => {
    if (type.status === "soldout" || type.status === "not_available") return;
    setActiveType({ ...type, quantity: 0 });
  };

  const closeModal = () => {
    setActiveType(null);
  };

  if (!types.length) return null;

  return (
    <>
      <div className={styles.tickets}>
        <Text variant="h4">{title ?? t("tickets_title")}</Text>
        <ul className={styles.tickets__list}>
          {types.map((type) => {
            return (
              <li className={styles.tickets__item} key={type.id}>
                <div
                  className={cx("tickets__itemBox", {
                    "tickets__itemBox--disabled":
                      type.status === "soldout" ||
                      type.status === "not_available",
                  })}
                  onClick={() => editType(type)}
                >
                  <div className={styles.tickets__itemName}>
                    <Text variant="h6" noMargin>
                      {type.name}
                    </Text>
                    <Text variant="p" noMargin>
                      {type.price
                        ? formatCurrency(
                            type.price,
                            account?.currency as Currency
                          )
                        : t("free")}
                    </Text>
                  </div>
                  {["available", "low"].includes(type.status) && (
                    <Button
                      onClick={(e) => {
                        e?.stopPropagation();
                        editType(type);
                      }}
                      icon
                    >
                      <RiAddLine />
                    </Button>
                  )}
                  {type.status !== "available" && (
                    <div className={styles.tickets__itemMessage}>
                      <Badge
                        size="small"
                        content={t(`status.${type.status}`)}
                        color={getBadgeColorFromStatus(type.status)}
                      />
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <EditTypeModal
        activeType={
          {
            ...activeType,
            showId: showId,
            seatmapId: seatmapId,
          } as ActiveChannelType
        }
        closeModal={closeModal}
      />
    </>
  );
};
