import { Shows } from "@components/Shows/Shows";
import { Show } from "@components/Show/Show";
import { Tickets } from "@components/Tickets/Tickets";

import { Channel as ChannelType } from "@type/channel";
import { ChannelEdition } from "@type/channelEdition";

interface StoreProps {
  activeShowId: string | null;
  setActiveShowId: (id: string | null) => void;
  edition: ChannelEdition;
  channel: ChannelType;
}

export const Store = (props: StoreProps) => {
  const { activeShowId, setActiveShowId, edition, channel } = props;

  return (
    <>
      {!activeShowId && (
        <div>
          {edition.channel?.shows && (
            <Shows
              shows={edition.channel.shows}
              showMode="list"
              setActiveShowId={setActiveShowId}
            />
          )}
          {edition.channel?.types && <Tickets types={edition.channel.types} />}
        </div>
      )}
      {activeShowId && (
        <Show
          storeUrl={channel.store}
          posToken={channel.pos_token}
          activeShowId={activeShowId}
          setActiveShowId={setActiveShowId}
        />
      )}
    </>
  );
};
