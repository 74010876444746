import { Navigate, createBrowserRouter } from "react-router-dom";

import { AppLayout } from "@layouts/AppLayout";

import { ChannelsWrapper } from "@components/ChannelsWrapper";

import { Carts } from "@pages/Carts";
import { Channel } from "@pages/Channel";
import { ChannelsOverview } from "@pages/ChannelsOverview";
import { Invitations } from "@pages/Invitations";
import { Orders } from "@pages/Orders";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <ChannelsWrapper />,
        children: [
          {
            path: "",
            element: <ChannelsOverview />,
          },
          {
            path: "/channel/:channelId",
            element: <Channel />,
          },
        ],
      },
      {
        path: "carts",
        element: <Carts />,
      },
      {
        path: "invitations",
        element: <Invitations />,
      },
      {
        path: "orders",
        element: <Orders />,
      },
      {
        // this is a legacy path from pos-one, we should redirect to root
        path: "login",
        element: <Navigate to="/" replace />,
      },
    ],
  },
]);
