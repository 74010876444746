import { formatTimer } from "@lib/helpers";
import styles from "./Timer.module.scss";
import { ChannelsContext } from "@components/ChannelsWrapper";
import { useOutletContext } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

interface TimerProps {
  setShowExpiredCartModal: (show: boolean) => void;
}

export const Timer = (props: TimerProps) => {
  const { setShowExpiredCartModal } = props;

  const { cart } = useOutletContext<ChannelsContext>();

  const calculateTimeLeft = useCallback(() => {
    const cartExpirationDate = cart?.expires_date
      ? cart.expires_date.getTime()
      : null;
    if (!cartExpirationDate) return undefined;

    const now = new Date().getTime();
    const difference = Math.round((cartExpirationDate - now) / 1000);

    return difference;
  }, [cart?.expires_date]);

  const [timeLeft, setTimeLeft] = useState<number | undefined>(
    calculateTimeLeft()
  );

  const letsShowTheExpiredCartModal = useCallback(() => {
    setShowExpiredCartModal(true);
  }, [setShowExpiredCartModal]);

  useEffect(() => {
    if (typeof timeLeft === "number" && timeLeft < 1) {
      letsShowTheExpiredCartModal();
      setTimeLeft(0);
      return;
    }

    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [calculateTimeLeft, letsShowTheExpiredCartModal, timeLeft]);

  useEffect(() => {}, [timeLeft]);

  if (typeof timeLeft === "undefined" || timeLeft > 86400) return null;

  return <div className={styles.timer}>{formatTimer(timeLeft)}</div>;
};
