import styles from "./PageLoader.module.scss";
import { Loader } from "@eventsquare/uikit";

export const PageLoader = () => {
  return (
    <div className={styles.pageLoader}>
      <Loader size="large" />
    </div>
  );
};
