import styles from "./DeleteInvitationModal.module.scss";
import { useState } from "react";
import { RiDeleteBinLine } from "@remixicon/react";
import {
  Alert,
  Button,
  Text,
  useAuth,
  Modal,
  ModalButtons,
} from "@eventsquare/uikit";

import { Api } from "@lib/api";

import { Invitation } from "@/types/invitation";
import { useTranslation } from "react-i18next";

interface DeleteInvitationModalProps {
  invitation: Invitation | null;
  setInvitationNull: () => void;
  refetchInvitations: () => void;
}

export const DeleteInvitationModal = (props: DeleteInvitationModalProps) => {
  const { invitation, setInvitationNull, refetchInvitations } = props;

  const { t } = useTranslation("components/delete_invitation_modal");
  const { accountId } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const deleteInvitation = async () => {
    if (!invitation) return;
    try {
      setError(null);
      setLoading(true);
      await Api.delete(
        `/accounts/${accountId}/pos/invitations/${invitation?.id}`
      );

      setInvitationNull();
      refetchInvitations();
    } catch (error) {
      // if (Api.isApiError(error)) {
      //   const err: string | null = error?.response?.body?.error ?? null;
      //   setError(err as string | null);
      // } else setError("something_went_wrong");
      const err = (error as { response: { body: { error: string } } }).response
        .body.error;
      setError(err ?? "something_went_wrong");
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    if (loading) return;
    setInvitationNull();
  };

  const closeError = () => {
    setError(null);
  };

  return (
    <Modal
      isVisible={!!invitation}
      closeModal={closeModal}
      icon={RiDeleteBinLine}
      iconColor="error"
    >
      <div className={styles.deleteInvitationModal}>
        <div className={styles.deleteInvitationModal__info}>
          <Text variant="h3" color="error">
            {t("title")}
          </Text>
          <Text variant="p">{t("lead")}</Text>
        </div>
        {error && (
          <div
            className={styles.deleteInvitationModal__error}
            onClick={closeError}
          >
            <Alert type="error" message={t(`errors.${error}`)} />
          </div>
        )}
        <ModalButtons>
          <Button
            block
            variant="outline"
            color="error"
            onClick={deleteInvitation}
            loading={loading}
          >
            {t("delete")}
          </Button>
          <Button block variant="link" color="subtle" onClick={closeModal}>
            {t("cancel")}
          </Button>
        </ModalButtons>
      </div>
    </Modal>
  );
};
