import { Badge, Button, Text, useAuth } from "@eventsquare/uikit";
import styles from "./ShowsList.module.scss";
import { RiArrowRightSLine } from "@remixicon/react";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";

import { ChannelShow } from "@type/channelEdition";

import { formatShowDate, getBadgeColorFromStatus } from "@lib/helpers";

const cx = classNames.bind(styles);

interface ShowsListProps {
  shows: ChannelShow[];
  setActiveShowId: (showId: string) => void;
}

export const ShowsList = (props: ShowsListProps) => {
  const { shows, setActiveShowId } = props;
  const { user } = useAuth();
  const { t } = useTranslation("components/shows");

  return (
    <div className={styles.showsList}>
      <ul className={styles.showsList__list}>
        {shows.map((show) => {
          return (
            <li className={styles.showsList__item} key={show.id}>
              <div
                className={cx("showsList__itemBox", {
                  "showsList__itemBox--disabled": show.status !== "available",
                })}
                onClick={() => setActiveShowId(show.id)}
              >
                <div className={styles.showsList__itemName}>
                  <Text variant="h6" noMargin>
                    {show.name && show.name.length > 0
                      ? show.name
                      : show.date.start
                      ? formatShowDate(
                          show.date.start,
                          show.date.hide_hours,
                          user?.language ?? "nl"
                        )
                      : show.id}
                  </Text>
                  {show.name && show.date.start && (
                    <Text variant="p" noMargin>
                      {formatShowDate(
                        show.date.start,
                        show.date.hide_hours,
                        user?.language ?? "nl"
                      )}
                    </Text>
                  )}
                  {show.status !== "available" && (
                    <Badge
                      size="small"
                      content={t(`status.${show.status}`)}
                      color={getBadgeColorFromStatus(show.status)}
                    />
                  )}
                </div>
                <Button
                  variant="outline"
                  onClick={(e) => {
                    e?.stopPropagation();
                    setActiveShowId(show.id);
                  }}
                  icon
                >
                  <RiArrowRightSLine />
                </Button>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
