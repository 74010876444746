import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import {
  Container,
  PageHeader,
  UIPage,
  FilterBar,
  FilterSelect,
  EventCard,
  EventGrid,
  useAuth,
  Empty,
} from "@eventsquare/uikit";
import { useTranslation } from "react-i18next";

import { ChannelsContext } from "@components/ChannelsWrapper";
import { PageLoader } from "@components/PageLoader/PageLoader";

import { getCookie } from "@lib/cookies";
import { getLocalStorage, setLocalStorage } from "@lib/localStorage";
import { formatDate, sortChannels } from "@lib/helpers";
import { RiShoppingCartLine } from "@remixicon/react";

type ChannelSortingType =
  | "edition_date"
  | "event_name"
  | "edition_creation_date";

const getInitialSortingState = () => {
  const localStorageSorting = getLocalStorage("eventSorting");
  if (localStorageSorting) {
    return localStorageSorting as ChannelSortingType;
  }

  return "event_name";
};

export const ChannelsOverview = () => {
  const { channels, channelsLoading } = useOutletContext<ChannelsContext>();
  const { t } = useTranslation("pages/channelsoverview");
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const cookie = getCookie();

    if (cookie) {
      navigate(`/channel/${cookie.channel_id}`, { replace: true });
    }
  }, []);

  const [sorting, setSorting] = useState<ChannelSortingType>(
    getInitialSortingState()
  );

  const changeSorting = (value: string) => {
    setSorting(value as ChannelSortingType);
    setLocalStorage("eventSorting", value);
  };

  return (
    <UIPage>
      <PageHeader title={t("title")} badge={channels?.length || undefined} />

      <Container>
        <FilterBar>
          <FilterSelect
            sort
            value={sorting}
            options={[
              { title: t("sort.edition_date"), value: "edition_date" },
              { title: t("sort.event_name"), value: "event_name" },
              {
                title: t("sort.edition_creation_date"),
                value: "edition_creation_date",
              },
            ]}
            onChange={changeSorting}
          />
        </FilterBar>
        {channelsLoading && <PageLoader />}
        {!channelsLoading && channels && channels.length === 0 && (
          <Empty
            title={t("empty.title")}
            lead={t("empty.lead")}
            icon={<RiShoppingCartLine />}
          />
        )}
        {!channelsLoading && channels && channels.length > 0 && (
          <>
            <EventGrid>
              {sortChannels(channels, sorting).map((channel, index) => {
                const title = channel.edition.name
                  ? channel.event.name + " - " + channel.edition.name
                  : channel.event.name;
                const banner =
                  channel.edition.branding.banner ??
                  channel.edition.branding.background.image ??
                  channel.event.branding.image;
                const logo =
                  channel.edition.branding.logo ?? channel.event.branding.logo;
                const backgroundColor =
                  channel.edition.branding.background.color ??
                  channel.event.branding.background.color;
                return (
                  <Link
                    to={`/channel/${channel.id}`}
                    key={`${index}_${channel.id}`}
                  >
                    <EventCard
                      key={`${index}_${channel.id}`}
                      title={title}
                      subtitle={
                        channel.edition.dates.start
                          ? formatDate(
                              channel.edition.dates.start,
                              user?.language ?? "nl"
                            )
                          : " "
                      }
                      banner={banner ?? undefined}
                      logo={logo ?? undefined}
                      color={channel.edition.branding.color ?? "#068CFC"}
                      backgroundColor={backgroundColor ?? "#068CFC"}
                      type={channel.event.type}
                      status={channel.event.live ? "live" : undefined}
                      popIn
                      index={index}
                    />
                  </Link>
                );
              })}
            </EventGrid>
          </>
        )}
      </Container>
    </UIPage>
  );
};
