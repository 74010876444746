import { useEffect, useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { ChannelLayout } from "@layouts/ChannelLayout/ChannelLayout";

import { Cart } from "@components/Cart/Cart";
import { ChannelsContext } from "@components/ChannelsWrapper";
import { ChannelTabs } from "@components/ChannelTabs/ChannelTabs";
import { Checkout } from "@components/Checkout/Checkout";
import { DeleteCartModal } from "@components/modals/DeleteCartModal/DeleteCartModal";
import { HasCartModal } from "@components/modals/HasCartModal/HasCartModal";
import { PageLoader } from "@components/PageLoader/PageLoader";
import { Store } from "@components/Store/Store";

import { Api } from "@lib/api";
import { CookiePOS, getCookie, removeCookie, setCookie } from "@lib/cookies";

import { ChannelStep, Channel as ChannelType } from "@type/channel";
import { ChannelEdition } from "@type/channelEdition";
import { Notes } from "@components/Notes/Notes";

interface ErrorResponse {
  body: {
    error?: string;
    validation?: { cart?: string };
  };
}

export const Channel = () => {
  const { channelId } = useParams<{ channelId: string }>();
  const { channels, channelsLoading, setCart, setCartLoading } =
    useOutletContext<ChannelsContext>();
  const [searchParams] = useSearchParams();

  const [channel, setChannel] = useState<ChannelType | null>(null);
  const [edition, setEdition] = useState<ChannelEdition | null>(null);
  const [activeShowId, setActiveShowId] = useState<string | null>(null);
  const [hasCartCookie, setHasCartCookie] = useState<CookiePOS | null>(null);
  const [deleteCart, setDeleteCart] = useState<{
    cartid: string;
    saved_at: string | null;
  } | null>(null);
  const [step, setStep] = useState<ChannelStep>("store");
  const navigate = useNavigate();

  useEffect(() => {
    if (activeShowId) return;
    // Only (re)check channel data if hasCartCookie is not set
    if (hasCartCookie) return;

    if (!channels || !channels.length) return;
    checkChannelData();
  }, [channels, channelId, activeShowId, hasCartCookie]);

  const checkChannelData = async () => {
    const cookie = getCookie();
    const queryCartId = searchParams.get("cart");

    if (!cookie) {
      // user has no cart cookie
      fetchChannelData();
      return;
    }

    if (!channels.find((channel) => channel.id === channelId)) {
      // the channel in url is not in the channels list
      // remove the cookie and return to home
      await removeCookie();
      navigate("/");
      return;
    }

    if (!channels.find((channel) => channel.id === cookie.channel_id)) {
      // the cookie channel is not in the channels list
      // remove the cookie and open the channel
      await removeCookie();
      fetchChannelData();
      return;
    }

    if (cookie.channel_id !== channelId) {
      // user has a cart cookie but it's not for this channel
      // if cookie has saved_at, it's a saved cart so we can delete cookie and open new cart
      if (cookie.saved_at) {
        await removeCookie();
        fetchChannelData();
        return;
      }
      // else ask if he wants to delete the cart or go back to session
      setHasCartCookie(cookie);
      return;
    }

    if (queryCartId && cookie.cart_id !== queryCartId) {
      // user probably comes from the options page
      // if cookie has saved_at, it's a saved cart so we can delete cookie and open the option from the query
      if (cookie.saved_at) {
        await removeCookie();
        fetchChannelData();
        return;
      }
      // else ask if he wants to delete the cart or go back to session
      setHasCartCookie(cookie);
      return;
    }

    fetchChannelData();
  };

  useEffect(() => {
    if (activeShowId && step !== "store") setStep("store");
  }, [activeShowId]);

  const fetchChannelData = async () => {
    if (!channels) return;
    try {
      setCartLoading(true);
      // find active channel by id
      const activeChannel = channels.find(
        (channel) => channel.id === channelId
      );
      if (!activeChannel) throw new Error("Channel not found");

      setChannel(activeChannel);

      const cookie = getCookie();

      // fetch edition based on channel
      let cart_id = searchParams.get("cart") ?? undefined;

      if (!cart_id) {
        if (cookie) {
          if (
            cookie.pos_token !== activeChannel.pos_token ||
            cookie.channel_id !== channelId
          ) {
            await removeCookie();
          } else {
            cart_id = cookie.cart_id;
          }
        }
      }

      const editionParams: {
        pos_token: string;
        cart?: string;
      } = {
        pos_token: activeChannel.pos_token,
        cart: cart_id,
      };

      const responseEdition = await Api.get(activeChannel.store, editionParams);
      setEdition(responseEdition.edition);

      const cartInResponse = responseEdition.edition.cart;

      if (!cartInResponse || !cartInResponse.cartid) {
        await removeCookie();
        setCart(null);
        navigate("/");
        return;
      }

      const responseCart = await Api.get(`/cart/${cartInResponse.cartid}`);

      setCookie(
        {
          cart_id: responseCart.cart.cartid,
          channel_id: channelId,
          edition_name: activeChannel.edition.name,
          event_name: activeChannel.event.name,
          pos_token: activeChannel.pos_token,
          reference: responseCart.cart.reference,
          saved_at: responseCart.cart.saved_at,
        },
        { maxAge: responseCart.cart.expires_in }
      );

      setCart(responseCart.cart);
    } catch (error) {
      // If you were send here with a cookie containing a cart that is invalid
      // remove the cookie and go back to the home page
      if (
        Api.isApiError(error) &&
        ((error.response as ErrorResponse)?.body?.validation?.cart ===
          "invalid" ||
          (error.response as ErrorResponse)?.body.error === "cart_fulfilled")
      ) {
        await removeCookie();
      } else {
        console.error(error);
      }
      navigate("/");
    } finally {
      setCartLoading(false);
    }
  };

  const getEventName = (channel: ChannelType) => {
    const eventName = channel.event.name;
    const editionName = channel.edition.name;

    return `${eventName}${editionName ? ` - ${editionName}` : ""}`;
  };

  if (hasCartCookie)
    return (
      <HasCartModal
        cookie={hasCartCookie}
        closeModal={() => setHasCartCookie(null)}
      />
    );

  if (channelsLoading || !channel || !edition) return <PageLoader />;

  return (
    <>
      <ChannelLayout
        event={getEventName(channel)}
        channel={edition.channel.name}
        setDeleteCart={setDeleteCart}
      >
        <div>
          <ChannelTabs step={step} setStep={setStep} />
          {step === "store" && (
            <Store
              activeShowId={activeShowId}
              setActiveShowId={setActiveShowId}
              edition={edition}
              channel={channel}
            />
          )}
          {step === "notes" && <Notes />}
          {step === "checkout" && <Checkout setStep={setStep} />}
        </div>
        <Cart
          step={step}
          setStep={setStep}
          setActiveShowId={setActiveShowId}
          setDeleteCart={setDeleteCart}
        />
      </ChannelLayout>
      <DeleteCartModal
        cart={deleteCart}
        setCartNull={() => setDeleteCart(null)}
        afterDelete={() => navigate("/")}
      />
    </>
  );
};
