import { useCallback, useEffect, useState } from "react";
import {
  Text,
  Table,
  TableHead,
  TableHeadCell,
  TableRow,
  TableBody,
  TableCell,
  UIPage,
  Container,
  PageHeader,
  useAuth,
  Button,
  Pagination,
  Empty,
  FilterBar,
  FilterSearch,
} from "@eventsquare/uikit";
import {
  RiCoupon2Line,
  RiExternalLinkLine,
  RiFileList2Line,
} from "@remixicon/react";
import { useTranslation } from "react-i18next";

import { formatCurrency, formatDateTime, getUserName } from "@lib/helpers";
import { Api } from "@lib/api";

import { Order, OrderCustomer } from "@/types/order";

import { PageLoader } from "@components/PageLoader/PageLoader";
import { NotesModal } from "@components/modals/NotesModal/NotesModal";

interface FetchParams {
  search?: string;
  limit?: number;
  page?: number;
}

const ORDERS_PER_PAGE = 25;

export const Orders = () => {
  const [orders, setOrders] = useState<Order[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [activePage, setActivePage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [orderSearch, setOrderSearch] = useState<string>("");
  const [totalOrders, setTotalOrders] = useState<number | undefined>(undefined);
  const [orderWithNotes, setOrderWithNotes] = useState<Order | null>(null);

  const { accountId, user } = useAuth();
  const { t } = useTranslation(["pages/orders", "components/pagination"]);

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    try {
      const params: FetchParams = {
        //search: "test",
        limit: ORDERS_PER_PAGE,
        page: activePage,
      };

      if (orderSearch.trim().length > 0) {
        params.search = orderSearch.trim();
      }

      const data = await Api.get(`/accounts/${accountId}/pos/orders`, params);
      setOrders(data.orders);
      setTotalOrders(data.meta.pagination.total);
      setLoading(false);
    } catch (error) {
      if (Api.isApiError(error)) {
        //console.log(error.response);
      }
    }
  }, [accountId, activePage, orderSearch]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setOrderSearch(search);
      setActivePage(1);
    }, 500);

    return () => clearTimeout(searchTimeout);
  }, [search]);

  const getCustomerName = (customer: OrderCustomer) => {
    if (customer?.firstname && customer?.lastname) {
      return `${customer.firstname} ${customer.lastname}`;
    }
    if (customer?.firstname) {
      return customer.firstname;
    }
    if (customer?.lastname) {
      return customer.lastname;
    }
    if (customer?.email) {
      return customer.email;
    }
    return "-";
  };

  const openTickets = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <UIPage>
      <PageHeader title={t("title")} badge={totalOrders || undefined} />
      <Container>
        <FilterBar>
          <FilterSearch
            value={search}
            setValue={setSearch}
            placeholder={t("filter.search")}
          />
        </FilterBar>
        {loading && <PageLoader />}
        {!loading && orders && orders.length === 0 && (
          <Empty
            icon={<RiCoupon2Line />}
            title={t("empty.title")}
            lead={t("empty.lead")}
          />
        )}
        {!loading && orders && orders.length > 0 && (
          <>
            <Table boxed>
              <TableHead>
                <TableRow>
                  <TableHeadCell>{t("reference")}</TableHeadCell>
                  <TableHeadCell>{t("customer")}</TableHeadCell>
                  <TableHeadCell>{t("event")}</TableHeadCell>
                  <TableHeadCell>{t("price")}</TableHeadCell>
                  <TableHeadCell>{t("date")}</TableHeadCell>
                  <TableHeadCell>{t("created_by")}</TableHeadCell>
                  <TableHeadCell></TableHeadCell>
                  <TableHeadCell></TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => {
                  return (
                    <TableRow key={order.id}>
                      <TableCell noWrap>
                        <Text variant="h6" component="span" noMargin>
                          <a href={order.pocket_url} target="_blank">
                            {order.reference}
                          </a>
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {getCustomerName(order.customer)}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {`${order.event.name}${
                            order.edition.name ? ` - ${order.edition.name}` : ""
                          }`}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {formatCurrency(order.price, order.currency)}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {order.processed_at
                            ? formatDateTime(
                                order.processed_at,
                                user?.language ?? "nl"
                              )
                            : "-"}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        <Text variant="p" component="span" noMargin>
                          {getUserName(order.user)}
                        </Text>
                      </TableCell>
                      <TableCell noWrap>
                        {!!order.cart && order.cart.notes.length >= 1 && (
                          <Button
                            icon
                            variant="outline"
                            size="tiny"
                            color="primary"
                            onClick={(e) => {
                              if (e) e.stopPropagation();
                              setOrderWithNotes(order);
                            }}
                          >
                            <RiFileList2Line />
                          </Button>
                        )}
                      </TableCell>
                      <TableCell noWrap>
                        <Button
                          variant="outline"
                          size="tiny"
                          onClick={() => openTickets(order.pocket_url)}
                        >
                          <RiExternalLinkLine />
                          {t("tickets")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Pagination
              activePage={activePage}
              itemsTotal={totalOrders ?? 0}
              itemsPerPage={ORDERS_PER_PAGE}
              setActivePage={setActivePage}
              translations={{
                first_page: t("components/pagination:first_page"),
                last_page: t("components/pagination:last_page"),
                active_page: t("components/pagination:active_page"),
              }}
            />
          </>
        )}
      </Container>
      <NotesModal
        notes={orderWithNotes?.cart.notes ?? null}
        closeModal={() => setOrderWithNotes(null)}
        title={orderWithNotes?.reference ?? undefined}
      />
    </UIPage>
  );
};
